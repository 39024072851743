import { Params } from '@angular/router';
import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const PULSE_ROUTE_PARAM_PROJECT_ID = 'projectId';
export const PULSE_ROUTE_PARAM_GROUP = 'group';
export const PULSE_ROUTE_PARAM_RESOURCE_ID = 'resourceId';
export const PULSE_ROUTE_PARAM_PARENT_ID = 'parentId';
export const PULSE_ROUTE_PARAMS = [
  PULSE_ROUTE_PARAM_PROJECT_ID,
  PULSE_ROUTE_PARAM_RESOURCE_ID,
  PULSE_ROUTE_PARAM_PARENT_ID,
  PULSE_ROUTE_PARAM_GROUP,
] as const;

export type PulseRouteParams = typeof PULSE_ROUTE_PARAMS[number];

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

/*
 * Beware of using this accumulation technique when two params with the same name exist in the route (e.g. my/:route/:id/with/another/:id).
 * Only the rightmost value is accessible because leftmost values are overwritten by the rightmost one in the traversal.
 *
 * */
export const selectRouteNestedParams = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state?.root;
  let params: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params;
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const selectRouteNestedParam = (param: PulseRouteParams) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  createSelector(selectRouteNestedParams, (params) => params && params[param]);

export const selectProjectId = createSelector(selectRouteNestedParam(PULSE_ROUTE_PARAM_PROJECT_ID), (paramId) =>
  paramId ? parseInt(paramId, 10) : -1
);

export const selectResourceId = createSelector(selectRouteNestedParam(PULSE_ROUTE_PARAM_RESOURCE_ID), (paramId) =>
  paramId ? parseInt(paramId, 10) : -1
);
export const selectParentId = createSelector(selectRouteNestedParam(PULSE_ROUTE_PARAM_PARENT_ID), (paramId) =>
  paramId ? parseInt(paramId, 10) : -1
);
export const selectGroup = createSelector(selectRouteNestedParam(PULSE_ROUTE_PARAM_GROUP), (param) => '' + param);

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getSelectors(selectRouter);
