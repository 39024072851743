import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createReducer,
  createSelector,
  MetaReducer,
  on,
} from '@ngrx/store';
import * as AWS from 'aws-sdk';
import { AccountConfig } from '../../models/AccountConfig';
import { AWSLoginStatus } from '../../models/AWSLoginStatus';
import { DeveloperRoles } from '../../models/DeveloperRoles';
import * as awsloginActions from '../actions/awslogin.actions';
import { IAWSIamGroup } from 'libs/feature/infrastructure-developer/src/lib/models/interfaces/IAWSIamGroup';

export interface AWSLoginState {
  status: AWSLoginStatus;
  isMfaLoggedIn: boolean;
  userName: string | null;
  userFirstName: string | null;
  userLastName: string | null;
  mfaExpiry: Date | null;
  mfaCredentials: AWS.Credentials | null;
  awsConsoleCredentials: AWS.Credentials | null;
  awsConsoleCredentialsStatus: string;
  awsConsoleCredentialsError: string | null;
  awsConsoleCredentialsExpiry: Date | null;
  awsConsoleMfaPanelOpen: boolean;
  pulseAccountCredentials: AWS.Credentials | null;
  accountConfigs: AccountConfig[]; // AccountNumber, Credentials
  accountConfigsStatus: string;
  accountConfigsError: string | null;
  selectedAccountConfig: AccountConfig | null;
  mfaPanelOpen: boolean;
  mfaError: string | null;
  lastMfaCode: string | null;
  awsCredentialsFile: {
    [key: string]: any;
  } | null;
  awsCredentialsFileStatus: string;
  awsCredentialsFileError: string | null;
  profileStatus: {
    [key: string]: any;
  } | null;
  iamGroups: IAWSIamGroup[];
  iamGroupsStatus: string;
  permissions: {
    [key: string]: string[];
  };
  permissionsStatus: string;
  queuedAwsConsoleUrl: string | null;
}

export const initialAWSLoginState: AWSLoginState = {
  status: AWSLoginStatus.NOT_LOGGED_IN,
  isMfaLoggedIn: false,
  userName: null,
  userFirstName: null,
  userLastName: null,
  mfaExpiry: null,
  mfaCredentials: null,
  pulseAccountCredentials: null,
  mfaPanelOpen: true,
  lastMfaCode: null,
  selectedAccountConfig: null,
  awsCredentialsFile: null,
  mfaError: null,
  accountConfigs: [],
  accountConfigsStatus: 'NOT_LOADED',
  accountConfigsError: null,
  awsCredentialsFileStatus: 'NOT_LOADED',
  awsCredentialsFileError: null,
  profileStatus: {},
  iamGroups: [],
  iamGroupsStatus: 'NOT_LOADED',
  permissions: {},
  permissionsStatus: 'NOT_LOADED',
  awsConsoleCredentials: null,
  awsConsoleCredentialsStatus: 'NOT_LOADED',
  awsConsoleCredentialsExpiry: null,
  awsConsoleMfaPanelOpen: false,
  awsConsoleCredentialsError: null,
  queuedAwsConsoleUrl: null,
};

export const awsLoginReducer = createReducer(
  initialAWSLoginState,
  on(awsloginActions.loginWithMfa, (state, { mfaCode }) => ({
    ...state,
    status: AWSLoginStatus.LOGGING_IN_WITH_MFA,
  })),
  on(awsloginActions.loginWithMfaSuccess, (state, { mfaCredentials, mfaExpiration }) => ({
    ...state,
    status: AWSLoginStatus.LOGGED_IN_WITH_MFA,
    isMfaLoggedIn: true,
    mfaCredentials: mfaCredentials,
    mfaExpiry: mfaExpiration,
    mfaError: null,
    mfaPanelOpen: false,
    awsCredentialsFile: {
      ...state.awsCredentialsFile,
      ['pulsemfa']: {
        aws_access_key_id: mfaCredentials.accessKeyId,
        aws_secret_access_key: mfaCredentials.secretAccessKey,
        aws_session_token: mfaCredentials.sessionToken,
        aws_expiration: mfaExpiration.toUTCString(),
      },
    },
  })),
  on(awsloginActions.loginWithMfaFailure, (state, { error }) => ({
    ...state,
    status: AWSLoginStatus.LOGIN_WITH_MFA_FAILED,
    isMfaLoggedIn: false,
    mfaCredentials: null,
    mfaError: error,
  })),
  on(awsloginActions.rotatePulseAccountCredentials, (state) => ({
    ...state,
    status: AWSLoginStatus.ROTATING_PULSE_ACCOUNT_CREDENTIALS,
  })),
  on(awsloginActions.rotatePulseAccountCredentialsSuccess, (state, { credentials }) => ({
    ...state,
    status: AWSLoginStatus.ROTATED_PULSE_ACCOUNT_CREDENTIALS,
    pulseAccountCredentials: credentials,
    awsCredentialsFile: {
      ...state.awsCredentialsFile,
      ['pulseaccount']: {
        aws_access_key_id: credentials.accessKeyId,
        aws_secret_access_key: credentials.secretAccessKey,
      },
    },
  })),
  on(awsloginActions.rotatePulseAccountCredentialsFailure, (state, { error }) => ({
    ...state,
    status: AWSLoginStatus.ROTATING_PULSE_ACCOUNT_CREDENTIALS_FAILED,
    mfaError: error,
  })),
  on(awsloginActions.updateAccountConfig, (state, { accountConfig }) => ({
    ...state,
    accountConfigs: state.accountConfigs
      .filter((x) => x.AccountNumber !== accountConfig.AccountNumber)
      .concat(accountConfig),
  })),
  on(awsloginActions.openMfaPanel, (state) => ({
    ...state,
    mfaPanelOpen: true,
  })),
  on(awsloginActions.closeMfaPanel, (state) => ({
    ...state,
    mfaPanelOpen: false,
  })),
  on(awsloginActions.toggleMfaPanel, (state) => ({
    ...state,
    mfaPanelOpen: !state.mfaPanelOpen,
  })),
  on(awsloginActions.setSelectedAccountConfig, (state, { accountConfig }) => ({
    ...state,
    selectedAccountConfig: accountConfig,
  })),
  on(awsloginActions.testAccountConfig, (state, { accountConfig }) => ({
    ...state,
  })),
  on(awsloginActions.setUserName, (state, { userName }) => ({
    ...state,
    userName: userName,
  })),
  on(awsloginActions.setLastMfaCode, (state, { lastMfaCode }) => ({
    ...state,
    lastMfaCode: lastMfaCode,
  })),

  //Reducer for new style aws login workflow
  //Load credentials file
  on(awsloginActions.loadAwsCredentialsFile, (state) => ({
    ...state,
    awsCredentialsFileStatus: 'LOADING',
  })),
  on(awsloginActions.loadAwsCredentialsFileSuccess, (state, { awsCredentialsFile }) => ({
    ...state,
    awsCredentialsFileStatus: 'LOADED',
    awsCredentialsFile: awsCredentialsFile,
    awsCredentialsFileError: null,
  })),
  on(awsloginActions.loadAwsCredentialsFileFailure, (state, { error }) => ({
    ...state,
    awsCredentialsFileStatus: 'FAILED_TO_LOAD',
    awsCredentialsFileError: error,
  })),
  //Save credentials file
  on(awsloginActions.saveAwsCredentialsFile, (state) => ({
    ...state,
    awsCredentialsFileStatus: 'SAVING',
  })),
  on(awsloginActions.saveAwsCredentialsFileSuccess, (state) => ({
    ...state,
    awsCredentialsFileStatus: 'SAVED',
    awsCredentialsFileError: null,
  })),
  on(awsloginActions.saveAwsCredentialsFileFailure, (state, { error }) => ({
    ...state,
    awsCredentialsFileStatus: 'FAILED_TO_SAVE',
    awsCredentialsFileError: error,
  })),
  //Load Pulse Account Credentials from credentials file
  on(awsloginActions.loadPulseAccountCredentials, (state) => ({
    ...state,
    profileStatus: { ...state.profileStatus, pulseaccount: 'LOADING_FROM_FILE' },
  })),
  on(awsloginActions.loadPulseAccountCredentialsSuccess, (state, { credentials, userName }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, pulseaccount: 'LOADED_FROM_FILE' },
    pulseAccountCredentials: credentials,
    userName: userName,
  })),
  on(awsloginActions.loadPulseAccountCredentialsFailure, (state, { error }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, pulseaccount: 'FAILED_TO_LOAD_FROM_FILE' },
  })),
  //Load MFA Credentials from credentials file
  on(awsloginActions.loadPulseMfaCredentials, (state) => ({
    ...state,
    profileStatus: { ...state.profileStatus, pulsemfa: 'LOADING_FROM_FILE' },
  })),
  on(awsloginActions.loadPulseMfaCredentialsSuccess, (state, { mfaCredentials, mfaExpiration }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, pulsemfa: 'LOADED_FROM_FILE' },
    mfaCredentials: mfaCredentials,
    isMfaLoggedIn: true,
    mfaError: null,
    status: AWSLoginStatus.LOGGED_IN_WITH_MFA,
    mfaPanelOpen: false,
    mfaExpiry: mfaExpiration,
  })),
  on(awsloginActions.loadPulseMfaCredentialsFailure, (state, { error }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, pulsemfa: 'FAILED_TO_LOAD_FROM_FILE' },
    isMfaLoggedIn: false,
    mfaError: error,
  })),

  //Load Pulse Account Configs
  on(awsloginActions.loadAccounts, (state) => ({
    ...state,
    accountConfigsStatus: 'LOADING',
  })),
  on(awsloginActions.loadAccountsSuccess, (state, { accounts }) => ({
    ...state,
    accountConfigsStatus: 'LOADED',
    accountConfigs: accounts,
  })),
  on(awsloginActions.loadAccountsFailure, (state, { error }) => ({
    ...state,
    accountConfigsStatus: 'FAILED_TO_LOAD',
    accountConfigsError: error,
  })),

  //Load Pulse Account Configs
  on(awsloginActions.loadAccountCredentials, (state, { accountConfig }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, [accountConfig.AccountNumber]: 'LOADING_FROM_FILE' },
  })),
  on(awsloginActions.loadAccountCredentialsSuccess, (state, { accountConfig }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, [accountConfig.AccountNumber]: 'LOADED_FROM_FILE' },
    accountConfigs: state.accountConfigs.map((x) =>
      x.AccountNumber === accountConfig.AccountNumber ? accountConfig : x
    ),
  })),
  on(awsloginActions.loadAccountCredentialsFailure, (state, { accountConfig, error }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, [accountConfig.AccountNumber]: 'FAILED_TO_LOAD_FROM_FILE' },
  })),

  //Refresh Credentials for Pulse Account Config
  on(awsloginActions.refreshPulseAppCredentials, (state) => ({
    ...state,
    profileStatus: { ...state.profileStatus, pulseapp: 'REFRESHING' },
  })),
  on(awsloginActions.refreshPulseAppCredentialsSuccess, (state, { account }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, pulseapp: 'REFRESHED' },
    accountConfigs: [...state.accountConfigs.filter((x) => x.AccountNumber !== account.AccountNumber), account],
    awsCredentialsFile: {
      ...state.awsCredentialsFile,
      [account.ProfileName]: {
        aws_access_key_id: account.Credentials?.accessKeyId,
        aws_secret_access_key: account.Credentials?.secretAccessKey,
        aws_session_token: account.Credentials?.sessionToken,
        aws_expiration: account.CredentialExpirationDate?.toUTCString(),
      },
    },
  })),
  on(awsloginActions.refreshPulseAppCredentialsFailure, (state, { error }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, pulseapp: 'FAILED_TO_REFRESH' },
  })),

  //Refresh Credentials for non Pulse App Account Configs
  on(awsloginActions.refreshNonPulseAppAccountCredentials, (state, { accountConfig }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, [accountConfig.ProfileName]: 'REFRESHING' },
  })),
  on(awsloginActions.refreshNonPulseAppAccountCredentialsSuccess, (state, { accountConfig }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, [accountConfig.ProfileName]: 'REFRESHED' },
    accountConfigs: [
      ...state.accountConfigs.filter((x) => x.AccountNumber !== accountConfig.AccountNumber),
      accountConfig,
    ],
    awsCredentialsFile: {
      ...state.awsCredentialsFile,
      [accountConfig.ProfileName]: {
        aws_access_key_id: accountConfig.Credentials?.accessKeyId,
        aws_secret_access_key: accountConfig.Credentials?.secretAccessKey,
        aws_session_token: accountConfig.Credentials?.sessionToken,
        aws_expiration: accountConfig.CredentialExpirationDate?.toUTCString(),
      },
    },
  })),
  on(awsloginActions.refreshNonPulseAppAccountCredentialsFailure, (state, { error, accountConfig }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, [accountConfig.ProfileName]: 'FAILED_TO_REFRESH' },
  })),
  on(awsloginActions.setInitialOnboardingConfig, (state, { pulseAccountCredentials, userName }) => ({
    ...state,
    pulseAccountCredentials: pulseAccountCredentials,
    userName: userName,
    awsCredentialsFile: {
      ...state.awsCredentialsFile,
      ['pulseaccount']: {
        aws_access_key_id: pulseAccountCredentials?.accessKeyId,
        aws_secret_access_key: pulseAccountCredentials?.secretAccessKey,
      },
    },
  })),
  on(awsloginActions.logoutMfa, (state) => ({
    ...state,
    isMfaLoggedIn: false,
    mfaCredentials: null,
    mfaError: 'MFA Credentials Expired, Please Login Again',
    status: AWSLoginStatus.NOT_LOGGED_IN,
    mfaPanelOpen: true,
  })),
  on(awsloginActions.removeConsoleSigninUrl, (state, { accountConfig }) => ({
    ...state,
    profileStatus: { ...state.profileStatus, [accountConfig.ProfileName]: 'CONSOLE_SIGNIN_URL_REMOVED' },
    accountConfigs: [
      ...state.accountConfigs.map((x) =>
        x.ProfileName === accountConfig.ProfileName
          ? { ...x, ConsoleConfigurationUrl: undefined, ConsoleSignInUrlCreationDate: undefined }
          : x
      ),
    ],
  })),
  on(awsloginActions.loadIamGroups, (state) => ({
    ...state,
    iamGroupsStatus: 'LOADING',
  })),
  on(awsloginActions.loadIamGroupsSuccess, (state, { iamGroups }) => ({
    ...state,
    iamGroupsStatus: 'LOADED',
    iamGroups: iamGroups,
  })),
  on(awsloginActions.loadIamGroupsFailure, (state, { error }) => ({
    ...state,
    iamGroupsStatus: 'FAILED_TO_LOAD',
  })),
  on(awsloginActions.loadPermissions, (state) => ({
    ...state,
    permissionsStatus: 'LOADING',
  })),
  on(awsloginActions.loadPermissionsSuccess, (state, { permissions }) => ({
    ...state,
    permissionsStatus: 'LOADED',
    permissions: permissions,
  })),
  on(awsloginActions.loadPermissionsFailure, (state, { error }) => ({
    ...state,
    permissionsStatus: 'FAILED_TO_LOAD ' + error,
  })),
  on(awsloginActions.assumeRoleForConsoleAccessWithMfa, (state) => ({
    ...state,
    awsConsoleCredentialsStatus: 'LOGGIN_IN',
  })),
  on(
    awsloginActions.assumeRoleForConsoleAccessWithMfaSuccess,
    (state, { assumedRoleForConsoleCredentials, expiration }) => ({
      ...state,
      awsConsoleCredentialsStatus: 'LOGGED_IN',
      awsConsoleCredentials: assumedRoleForConsoleCredentials,
      awsConsoleCredentialsExpiry: expiration,
      awsConsoleMfaPanelOpen: false,
      awsConsoleCredentialsError: null,
      awsCredentialsFile: {
        ...state.awsCredentialsFile,
        ['pulseawsconsole']: {
          aws_access_key_id: assumedRoleForConsoleCredentials?.accessKeyId,
          aws_secret_access_key: assumedRoleForConsoleCredentials?.secretAccessKey,
          aws_session_token: assumedRoleForConsoleCredentials?.sessionToken,
          aws_expiration: expiration?.toUTCString(),
        },
      },
    })
  ),
  on(awsloginActions.openLinkInAwsConsole, (state, { url }) => ({
    ...state,
    queuedAwsConsoleUrl: url,
  })),
  on(awsloginActions.openLinkInAwsConsoleSuccess, (state, { url }) => ({
    ...state,
    queuedAwsConsoleUrl: null,
  })),
  on(awsloginActions.signInToAwsConsoleSuccess, (state) => ({
    ...state,
    queuedAwsConsoleUrl: null,
  })),

  on(awsloginActions.loadConsoleRoleSuccess, (state, { credentials, expiration }) => ({
    ...state,
    awsConsoleCredentialsStatus: 'LOGGED_IN',
    awsConsoleCredentials: credentials,
    awsConsoleCredentialsExpiry: expiration,
    awsConsoleMfaPanelOpen: false,
    awsConsoleCredentialsError: null,
  })),

  on(awsloginActions.assumeRoleForConsoleAccessWithMfaFailure, (state, { error }) => ({
    ...state,
    awsConsoleCredentialsStatus: 'FAILED_TO_LOGIN',
    awsConsoleCredentialsError: error,
  })),
  on(awsloginActions.openAwsConsoleMfaPanel, (state) => ({
    ...state,
    awsConsoleMfaPanelOpen: true,
  })),
  on(awsloginActions.closeAwsConsoleMfaPanel, (state) => ({
    ...state,
    awsConsoleMfaPanelOpen: false,
  })),
  //Overrides the default profile with the current pulseapp profile
  on(awsloginActions.overrideDefaultProfileWithPulseAppProfile, (state) => ({
    ...state,
    awsCredentialsFile: state.awsCredentialsFile
      ? {
          ...state.awsCredentialsFile,
          ['default']: {
            aws_access_key_id: state.awsCredentialsFile['pulseapp'].aws_access_key_id,
            aws_secret_access_key: state.awsCredentialsFile['pulseapp'].aws_secret_access_key,
            aws_session_token: state.awsCredentialsFile['pulseapp'].aws_session_token,
            aws_expiration: state.awsCredentialsFile['pulseapp'].aws_expiration,
          },
        }
      : null,
  }))
);
