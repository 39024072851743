import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SidebarItemNavigationType } from '../../enums';
import { SidebarItem } from '../../interfaces';

@Injectable()
export class SidebarService {
  private _subMenuItems$$: Subject<SidebarItem[]> = new Subject<SidebarItem[]>();
  private _subMenuCloseEvent$$ = new Subject<unknown>();
  private _collapse$$ = new BehaviorSubject<boolean>(false);
  private _subMenuParentItem$$ = new BehaviorSubject<SidebarItem | null>(null);
  private _projectId: number;

  public navigationItemsTop?: SidebarItem[];
  public navigationItemsBottom?: SidebarItem[];
  public navigationItemsMiddle?: SidebarItem[];
  public isSubMenuVisible = false;
  public readonly subMenuCloseEvent$ = this._subMenuCloseEvent$$.asObservable();
  public readonly subMenuItems$ = this._subMenuItems$$.asObservable();
  public readonly collapse$ = this._collapse$$.asObservable();
  public readonly subMenuParentItem$ = this._subMenuParentItem$$.asObservable();

  public static FindSidebarItem(items: SidebarItem[], ...path: string[]): SidebarItem | null {
    const needle = path.shift();
    const found = items.find((item) => item.path === needle);
    if (found) {
      if (path.length === 0) {
        return found;
      }
      if (found.children && found.children.length > 0) {
        return this.FindSidebarItem(found.children, ...path);
      }
    }

    return null;
  }

  public collapseSidebar(state: boolean): void {
    this._collapse$$.next(state);
  }

  public setProjectId(id: number): void {
    this._projectId = id;
  }

  public showItem(item: SidebarItem): boolean {
    if (item.navigationType === SidebarItemNavigationType.Submenu) {
      return true;
    }
    if (item.data && item.data.navigation) {
      if (item.data.checkChildren && item.children) {
        for (const child of item.children) {
          if (this.showItem(child)) {
            return true;
          }
        }
      } else {
        return true;
      }
    }

    return false;
  }

  public setPath(item: SidebarItem, parentPath: string): void {
    let path = '';
    if (item.directPath) {
      if (item.directPath.indexOf('http') === 0) {
        path = item.directPath;
      }
      path = item.directPath;
    } else {
      if (item.path) {
        path = parentPath + '/' + item.path;
      } else {
        path = undefined;
      }
    }
    if (!path.includes('project') && !item.directPath?.startsWith('http')) {
      item.directPath = `/project/${this._projectId}/${path}`;
    }
    item.currentPath = path;
  }

  public isSelected(item: SidebarItem, compareRoute: string): boolean {
    const compareWith = item.directPath ?? item.currentPath ?? item.path ?? '';

    if (item.data && item.data.ignoreSubpath) {
      if (compareWith && compareRoute.startsWith(compareWith)) {
        return true;
      }
    }
    if (compareWith && compareRoute === compareWith) {
      return true;
    }

    return false;
  }

  public isActive(item: SidebarItem, compareRoute: string): boolean {
    if (item.children) {
      return this._itemActive(item, compareRoute);
    }

    return false;
  }

  public hasChildrenData(item: SidebarItem): boolean {
    if (item.children) {
      return item.children.some((x) => x.data && x.data.navigation);
    }

    return false;
  }

  private _itemActive(item: SidebarItem, compareRoute: string): boolean {
    if (this.isSelected(item, compareRoute)) {
      return true;
    }

    if (item.children) {
      for (const child of item.children) {
        this.setPath(child, item.currentPath);
        if (this._itemActive(child, compareRoute)) {
          return true;
        }
      }
    }

    return false;
  }

  public setSubMenuItems(parentItem: SidebarItem): void {
    const subMenuItems$ = parentItem.subMenuItemSource;
    subMenuItems$?.subscribe((items: SidebarItem[]) => {
      this._subMenuItems$$.next(items);
    });
    this._subMenuParentItem$$.next(parentItem);
  }

  /** determines whether a given sidebar item is the current submenu parent */
  public isCurrentSubMenuParentItem(item: SidebarItem): boolean {
    return item === this._subMenuParentItem$$.value;
  }

  public toggleSubMenu(isVisible: boolean): void {
    this.isSubMenuVisible = isVisible;
  }

  public emitSubMenuCloseEvent(): void {
    this._subMenuCloseEvent$$.next();
  }
}
