import { createAction, props } from '@ngrx/store';

/**
 * This is the core noop action.
 * It should be ALWAYS ignored and not used in any effect order reducer!
 */
export const noopAction = createAction('[Core] Noop');

export const showSuccessInToasty = createAction('[Core] Show Success in Toasty');
export const showErrorInToasty = createAction(
  '[Core] Show Error in Toasty',
  props<{ error: unknown; fallbackMessage?: string }>()
);
export const showWarningInToasty = createAction('[Core] Show Warning in Toasty', props<{ message: string }>());
