import { Inject, Injectable, Optional } from '@angular/core';
import { HttpCacheClient } from '@pulse/api/http-cache';
import { API_BASE_URL, ClientV2 } from './api-client-v2';

@Injectable()
export class ClientV2Cached extends ClientV2 {
  constructor(httpClient: HttpCacheClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(httpClient, baseUrl);
  }
}
