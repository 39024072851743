import { HttpBackend, HttpClient, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InterceptingHandler } from '../../models/intercepting-handler.class';
import { CACHE_HTTP_INTERCEPTORS } from '../../tokens/cache-http-interceptor.token';

@Injectable()
export class HttpCacheClient extends HttpClient {
  constructor(
    backend: HttpBackend,
    @Inject(HTTP_INTERCEPTORS) interceptors: HttpInterceptor[],
    @Inject(CACHE_HTTP_INTERCEPTORS) featureInterceptors: HttpInterceptor[]
  ) {
    super(new InterceptingHandler(backend, interceptors.concat(featureInterceptors)));
  }
}
