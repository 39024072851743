import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EnvironmentService } from '@pulse/util/environment';

@Component({
  selector: 'pulse-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private readonly _environmentService: EnvironmentService, private _titleService: Title) {
    this._titleService.setTitle(this._environmentService.getTitle());
  }
}
