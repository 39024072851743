import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidebarItem } from '../../interfaces';

@Component({
  selector: 'pulse-sidebar-submenu',
  templateUrl: './sidebar-submenu.component.html',
  styleUrls: ['./sidebar-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarSubmenuComponent {
  @HostListener('keydown.escape', ['$event'])
  handleKeyboardEvent(): void {
    this.closeSubmenu();
  }

  private readonly _allItems$$ = new BehaviorSubject<SidebarItem[]>([]);
  private readonly _filter$$ = new BehaviorSubject<string>('');

  public readonly filteredItems$ = combineLatest([this._allItems$$, this._filter$$]).pipe(
    map(([allItems, filter]) =>
      filter
        ? allItems.filter((item) => (item.title ? item.title.toLowerCase().indexOf(filter) !== -1 : false))
        : allItems
    )
  );

  @Input() public hasSearchBar = true;
  @Input() public titleIcon = '';
  @Input() public titleLabel = '';
  @Output() public closeClick = new EventEmitter<string>();

  @Input()
  public set itemList(itemList: SidebarItem[]) {
    this._allItems$$.next(itemList);
  }

  public filterItems(term: string): void {
    this._filter$$.next(term);
  }

  public closeSubmenu(): void {
    this.closeClick.emit('close');
  }
}
