<ng-container
  *ngIf="{ selected: selected$ | async, active: active$ | async } as data"
>
  <button
    class="sidebar-item"
    [class.highlight]="data.selected"
    [class.child]="this.parentIcon.length > 0"
    (click)="emitClick(data.selected)"
    [attr.tabindex]="
      item.navigationType === itemNavigationType.Submenu &&
      item.subMenuItemSource
        ? '0'
        : '-1'
    "
  >
    <ng-container [ngSwitch]="item.navigationType">
      <ng-container *ngSwitchCase="itemNavigationType.Path">
        <a
          *ngIf="!isExternalLink && path"
          [routerLink]="path"
          [attr.title]="title | transloco"
          (click)="toggleActive(data.active, false)"
          class="sidebar-link"
        >
          <pulse-sidebar-link
            [title]="title"
            [icon]="icon"
          ></pulse-sidebar-link>
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="itemNavigationType.Link">
        <a
          *ngIf="isExternalLink && path"
          [target]="target"
          [href]="path"
          [attr.title]="title | transloco"
          (click)="toggleActive(data.active, false)"
          class="sidebar-link"
        >
          <pulse-sidebar-link
            [title]="title"
            [icon]="icon"
          ></pulse-sidebar-link>
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="itemNavigationType.Noaction">
        <a
          href="javascript:;"
          [attr.title]="title | transloco"
          (click)="toggleActive(data.active, true)"
          class="sidebar-link"
        >
          <pulse-sidebar-link
            [title]="title"
            [icon]="icon"
          ></pulse-sidebar-link>
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="itemNavigationType.Submenu">
        <a [attr.title]="title | transloco" class="sidebar-link">
          <pulse-sidebar-link
            [title]="title"
            [delegateUserName]="delegateUserName"
            [icon]="icon"
          ></pulse-sidebar-link>
        </a>
      </ng-container>
    </ng-container>

    <i *ngIf="item.data?.badge" class="beta-tag">{{ item.data?.badge }}</i>
    <button
      *ngIf="service.hasChildrenData(item)"
      class="action"
      [attr.aria-label]="
        'show or hide children navbar items of ' + (title | transloco)
      "
      [ngClass]="{
        'icon-arrow_up': data.active,
        'icon-arrow_down': data.active === false
      }"
      (click)="toggleActive(data.active, true)"
    ></button>
    <i
      *ngIf="
        item.navigationType === itemNavigationType.Submenu &&
        item.subMenuItemSource
      "
      class="action icon-arrow_right"
    ></i>
  </button>

  <pulse-sidebar-itemlist
    *ngIf="item.children && data.active"
    [items]="item.children"
    [childrenContainerId]="item.data?.childrenContainerId ?? ''"
    [icon]="icon"
    [path]="path"
    (itemClick)="emitClick(data.selected, true)"
  ></pulse-sidebar-itemlist>
</ng-container>
