import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { CheckmarkModule } from '../checkmark';
import { MessageComponent } from './components/message/message.component';
import { ToastyComponent } from './components/toasty/toasty.component';

@NgModule({
  declarations: [MessageComponent, ToastyComponent],
  imports: [CommonModule, TranslocoModule, CheckmarkModule],
  exports: [ToastyComponent],
})
export class ToastyModule {}
