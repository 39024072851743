import { createAction, props } from '@ngrx/store';
import { AccountConfig } from '../../models/AccountConfig';
import { DeveloperRoles } from '../../models/DeveloperRoles';
import { IAWSIamGroup } from 'libs/feature/infrastructure-developer/src/lib/models/interfaces/IAWSIamGroup';

/**
 * Account / Profile Login Actions
 */

export const updateAccountConfig = createAction(
  '[AWSLogin] Update Credentials for Account',
  props<{ accountConfig: AccountConfig }>()
);

export const setSelectedAccountConfig = createAction(
  '[AWSLogin] Select Account',
  props<{ accountConfig: AccountConfig }>()
);

/**
 * MFA Panel Actions
 */

export const openMfaPanel = createAction('[AWSLogin] Open MFA Panel');

export const closeMfaPanel = createAction('[AWSLogin] Close MFA Panel');

export const toggleMfaPanel = createAction('[AWSLogin] Toggle MFA Panel');

/**
 * Setting username / last mfa code possibly not needed
 */

export const setUserName = createAction('[AWSLogin] Set User Name', props<{ userName: string }>());

export const setLastMfaCode = createAction('[AWSLogin] Set Last MFA Code', props<{ lastMfaCode: string }>());

/**
 * Load ~/.aws/credentials file
 */
export const loadAwsCredentialsFile = createAction('[AWSLogin] Load AWS Credentials File');
export const loadAwsCredentialsFileSuccess = createAction(
  '[AWSLogin] Load AWS Credentials File Success',
  props<{
    awsCredentialsFile: {
      [key: string]: any;
    };
  }>()
);
export const loadAwsCredentialsFileFailure = createAction(
  '[AWSLogin] Load AWS Credentials File Failure',
  props<{ error: string }>()
);

/**
 * Save ~/.aws/credentials file
 */
export const saveAwsCredentialsFile = createAction('[AWSLogin] Save Credentials File');
export const saveAwsCredentialsFileSuccess = createAction('[AWSLogin] Save Credentials File Success');
export const saveAwsCredentialsFileFailure = createAction(
  '[AWSLogin] Save Credentials File Failure',
  props<{ error: string }>()
);

/**
 * Load pulseaccount credentials from config file
 */

export const loadPulseAccountCredentials = createAction('[AWSLogin] Load Pulse Account Credentials');
export const loadPulseAccountCredentialsSuccess = createAction(
  '[AWSLogin Pulse Account Credentials] Load Pulse Account Credentials Success',
  props<{ credentials: AWS.Credentials; userName: string }>()
);
export const loadPulseAccountCredentialsFailure = createAction(
  '[AWSLogin Pulse Account Credentials] Load Pulse Account Credentials Failure',
  props<{ error: string }>()
);

/**
 * Rotate Pulse Account Credentials
 */

export const rotatePulseAccountCredentials = createAction('[AWSLogin] Rotate Pulse Account Credentials');
export const rotatePulseAccountCredentialsSuccess = createAction(
  '[AWSLogin Pulse Account Credentials] Rotate Pulse Account Credentials Success',
  props<{ credentials: AWS.Credentials }>()
);
export const rotatePulseAccountCredentialsFailure = createAction(
  '[AWSLogin Pulse Account Credentials] Rotate Pulse Account Credentials Failure',
  props<{ error: string }>()
);

/**
 * Load pulsemfa credentials from config file
 */

export const loadPulseMfaCredentials = createAction('[AWSLogin] Load Pulse MFA Credentials');
export const loadPulseMfaCredentialsSuccess = createAction(
  '[AWSLogin Pulse MFA Credentials] Load Pulse MFA Credentials Success',
  props<{ mfaCredentials: AWS.Credentials; mfaExpiration: Date }>()
);
export const loadPulseMfaCredentialsFailure = createAction(
  '[AWSLogin Pulse MFA Credentials] Load Pulse MFA Credentials Failure',
  props<{ error: string }>()
);

/**
 * Load accounts from AWS
 */

export const loadAccounts = createAction('[AWSLogin] Load Accounts');
export const loadAccountsSuccess = createAction(
  '[AWSLogin] Load Accounts Success',
  props<{ accounts: AccountConfig[] }>()
);
export const loadAccountsFailure = createAction('[AWSLogin] Load Accounts Failure', props<{ error: string }>());

/**
 * Load credentials for all account from config file
 */

export const loadAllAccountCredentials = createAction('[AWSLogin] Load All Account Credentials');
export const loadAllAccountCredentialsSuccess = createAction(
  '[AWSLogin All Account Credentials] Load All Account Credentials Success',
  props<{ accountConfigs: AccountConfig[] }>()
);
export const loadAllAccountCredentialsFailure = createAction(
  '[AWSLogin All Account Credentials] Load All Account Credentials Failure',
  props<{ error: string }>()
);

/**
 * Load credentials for account from config file
 */

export const loadAccountCredentials = createAction(
  '[AWSLogin] Load Account Credentials',
  props<{ accountConfig: AccountConfig }>()
);
export const loadAccountCredentialsSuccess = createAction(
  '[AWSLogin Account Credentials] Load Account Credentials Success',
  props<{ accountConfig: AccountConfig }>()
);
export const loadAccountCredentialsFailure = createAction(
  '[AWSLogin Account Credentials] Load Account Credentials Failure',
  props<{ error: string; accountConfig: AccountConfig }>()
);

/**
 * Refresh credentials for PulseApp account
 */

export const refreshPulseAppCredentials = createAction('[AWSLogin] Refresh PulseApp Credentials');
export const refreshPulseAppCredentialsSuccess = createAction(
  '[AWSLogin PulseApp Credentials] Refresh PulseApp Credentials Success',
  props<{ account: AccountConfig }>()
);
export const refreshPulseAppCredentialsFailure = createAction(
  '[AWSLogin PulseApp Credentials] Refresh PulseApp Credentials Failure',
  props<{ error: string }>()
);

/**
 * Refresh credentials for non PulseApp account
 */

export const refreshNonPulseAppAccountCredentials = createAction(
  '[AWSLogin Account Credentials] Refresh Non Pulse App Account Credentials',
  props<{ accountConfig: AccountConfig }>()
);
export const refreshNonPulseAppAccountCredentialsSuccess = createAction(
  '[AWSLogin Account Credentials] Refresh Non Pulse App Account Credentials Success',
  props<{ accountConfig: AccountConfig }>()
);
export const refreshNonPulseAppAccountCredentialsFailure = createAction(
  '[AWSLogin Account Credentials] Refresh Non Pulse App Account Credentials Failure',
  props<{ accountConfig: AccountConfig; error: string }>()
);

/**
 * Logout MFA
 */
export const logoutMfa = createAction('[AWSLogin] Logout MFA');

/**
 * Login MFA
 */
export const loginWithMfa = createAction('[AWSLogin] Login with MFA', props<{ mfaCode: string }>());
export const loginWithMfaSuccess = createAction(
  '[AWSLogin] Login with MFA Success',
  props<{ mfaCredentials: AWS.Credentials; mfaExpiration: Date }>()
);
export const loginWithMfaFailure = createAction('[AWSLogin] Login with MFA Failure', props<{ error: string }>());

/**
 * Test account credentials
 */
export const testAccountConfig = createAction(
  '[AWSLogin] Test Account Config',
  props<{ accountConfig: AccountConfig }>()
);

export const testAccountConfigSuccess = createAction(
  '[AWSLogin] Test Account Config Success',
  props<{ accountConfig: AccountConfig }>()
);

export const testAccountConfigFailure = createAction(
  '[AWSLogin] Test Account Config Failure',
  props<{ accountConfig: AccountConfig; error: string }>()
);

/**
 * Set initial config after onboarding
 */

export const setInitialOnboardingConfig = createAction(
  '[AWSLogin] Set Initial Config',
  props<{
    pulseAccountCredentials: AWS.Credentials;
    userName: string;
  }>()
);

//Load console role from config file
export const loadConsoleRole = createAction('[AWSLogin] Load Console Role');
export const loadConsoleRoleSuccess = createAction(
  '[AWSLogin] Load Console Role Success',
  props<{ credentials: AWS.Credentials; expiration: Date }>()
);
export const loadConsoleRoleFailure = createAction('[AWSLogin] Load Console Role Failure', props<{ error: string }>());

//Generate console signin url for account
export const signInToAwsConsole = createAction('[AWSLogin] Signin to AWS Console');
export const signInToAwsConsoleSuccess = createAction('[AWSLogin] Signin to AWS Console Success');
export const signInToAwsConsoleFailure = createAction(
  '[AWSLogin] Signin to AWS Console Failure',
  props<{ error: string }>()
);

export const openLinkInAwsConsole = createAction('[AWSLogin] Open Link in AWS Console', props<{ url: string }>());
export const openLinkInAwsConsoleSuccess = createAction(
  '[AWSLogin] Open Link in AWS Console Success',
  props<{ url: string }>()
);
export const openLinkInAwsConsoleFailure = createAction(
  '[AWSLogin] Open Link in AWS Console Failure',
  props<{ url: string; error: string }>()
);

export const assumeRoleForConsoleAccessWithMfa = createAction(
  '[AWSLogin] Signin to AWS Console with MFA',
  props<{ mfaCode: string; roleToAssume: string }>()
);
export const assumeRoleForConsoleAccessWithMfaSuccess = createAction(
  '[AWSLogin] Signin to AWS Console with MFA Success',
  props<{ assumedRoleForConsoleCredentials: AWS.Credentials; expiration: Date }>()
);
export const assumeRoleForConsoleAccessWithMfaFailure = createAction(
  '[AWSLogin] Signin to AWS Console with MFA Failure',
  props<{ error: string }>()
);

export const openAwsConsoleMfaPanel = createAction('[AWSLogin] Open AWS Console MFA Panel');
export const closeAwsConsoleMfaPanel = createAction('[AWSLogin] Close AWS Console MFA Panel');

/**
 * Remove signin url for account
 */
export const removeConsoleSigninUrl = createAction(
  '[AWSLogin] Remove Console Signin Url',
  props<{ accountConfig: AccountConfig }>()
);

/**
 * Load IAM Groups for user
 */

export const loadIamGroups = createAction('[AWSLogin] Load IAM Groups');
export const loadIamGroupsSuccess = createAction(
  '[AWSLogin] Load IAM Groups Success',
  props<{ iamGroups: IAWSIamGroup[] }>()
);
export const loadIamGroupsFailure = createAction('[AWSLogin] Load IAM Groups Failure', props<{ error: string }>());

/**
 * Load permissions from DynamoDb
 */

export const loadPermissions = createAction('[AWSLogin] Load Permissions');
export const loadPermissionsSuccess = createAction(
  '[AWSLogin] Load Permissions Success',
  props<{ permissions: { [key: string]: string[] } }>()
);
export const loadPermissionsFailure = createAction('[AWSLogin] Load Permissions Failure', props<{ error: string }>());

export const overrideDefaultProfileWithPulseAppProfile = createAction(
  '[AWSLogin] Override Default Profile With Pulse App Profile'
);

export const noOpAction = createAction('[AWSLogin] No Op Action');
