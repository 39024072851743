import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PULSE_ENVIRONMENT } from './tokens';
import { IEnvironment } from './interface';

@NgModule({
  imports: [CommonModule],
})
export class EnvironmentModule {
  static forRoot(environment: IEnvironment): ModuleWithProviders<EnvironmentModule> {
    return {
      ngModule: EnvironmentModule,
      providers: [
        {
          provide: PULSE_ENVIRONMENT,
          useValue: environment,
        },
      ],
    };
  }
}
