import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import { SidebarItemNavigationType } from '../../enums';
import { SidebarItem, SidebarSubmenuItem, SidebarUserItem } from '../../interfaces';
import { SidebarService } from '../../services';

@Component({
  selector: 'pulse-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarItemComponent implements OnDestroy, OnInit, AfterViewInit, OnChanges {
  @Input() public item: SidebarItem;
  @Input() public parentPath = '';
  @Input() public parentIcon = '';
  @Input() public navigationElement: HTMLElement;
  @Output() public itemClick = new EventEmitter<void>();

  private readonly _subscription: Subscription = new Subscription();
  private readonly _active$$ = new BehaviorSubject<boolean>(false);
  private readonly _selected$$ = new BehaviorSubject<boolean>(false);

  public readonly active$ = this._active$$.asObservable();
  public readonly selected$ = this._selected$$.asObservable();

  public title = '';
  public delegateUserName = '';
  public icon = '';
  public path?: string;
  public isExternalLink = false;
  public target = '_blank';
  public itemNavigationType = SidebarItemNavigationType;

  constructor(
    private readonly _router: Router,
    private readonly _renderer: Renderer2,
    public service: SidebarService
  ) {}

  public ngOnInit(): void {
    this._initProperties();

    this._subscribeToCompereRoutes();
    this._subscribeUnselectItem();
  }

  public ngAfterViewInit(): void {
    this._subscribeToSetActiveClass();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      this._initProperties();
      this._verifySelectedState(this._router.routerState.snapshot.url);
      this._verifyActiveSate(this._router.routerState.snapshot.url);
    }
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _initProperties(): void {
    if (this.item.navigationType === SidebarItemNavigationType.Link) {
      this.target = this.item.isTargetBlank ? '_blank' : '_self';
    }
    this.title = this._getTitle(this.item);
    this.delegateUserName = (this.item as SidebarUserItem)?.delegateUserName || '';
    this.path = this._getPath(this.item, this.parentPath);
    this.item.currentPath = this.path;
    this.isExternalLink = this._isExternalLink(this.item);
    this.icon = this._getIcon(this.item, this.parentIcon);
  }

  private _getIcon(item: SidebarItem, parentIcon: string): string {
    if (item.navigationType === SidebarItemNavigationType.Submenu) {
      return (item as SidebarUserItem).icon;
    } else {
      if (parentIcon.length > 0) {
        if (item.data && item.data.icon) {
          return 'small ' + item.data.icon;
        } else {
          return 'small ' + parentIcon;
        }
      } else if (item.data && item.data.icon) {
        return item.data.icon;
      }
    }
  }

  private _getTitle(item: SidebarItem): string {
    if (item.navigationType === SidebarItemNavigationType.Submenu) {
      return (item as SidebarSubmenuItem).title;
    } else {
      return item.data.navigation ? item.data.navigation : item.path;
    }
  }

  private _getPath(item: SidebarItem, parentPath: string): string | undefined {
    if (item.directPath) {
      return item.directPath;
    } else {
      return item.path ? parentPath + '/' + item.path : undefined;
    }
  }

  private _isExternalLink(item: SidebarItem): boolean {
    return item.directPath && item.directPath.indexOf('http') === 0 ? true : false;
  }

  private _verifySelectedState(route: string): void {
    this._selected$$.next(this.service.isSelected(this.item, route));
  }

  private _verifyActiveSate(route: string): void {
    this._active$$.next(this.service.isActive(this.item, route));
  }

  public toggleActive(isActive: boolean, fromArrow = true): void {
    if (isActive && !fromArrow) return;
    this._active$$.next(!isActive);
  }

  public emitClick(isSelected: boolean, fromChild = false): void {
    if (this.item.navigationType !== SidebarItemNavigationType.Submenu) {
      if (!this.service.hasChildrenData(this.item) || fromChild) {
        this.itemClick.emit();
      }
    } else {
      if (this.item.subMenuItemSource) {
        if (this.service.isCurrentSubMenuParentItem(this.item)) {
          this.service.toggleSubMenu(!isSelected);
          this._selected$$.next(!isSelected);
        } else {
          this.service.setSubMenuItems(this.item);
          this.service.toggleSubMenu(true);
          this._selected$$.next(true);
        }
      }
    }
  }

  private _subscribeToSetActiveClass(): void {
    this._subscription.add(
      this.active$.subscribe((a) => {
        if (a) {
          this._renderer.addClass(this.navigationElement, 'active');
        } else {
          this._renderer.removeClass(this.navigationElement, 'active');
        }
      })
    );
  }

  private _subscribeToCompereRoutes(): void {
    this._subscription.add(
      this._router.events.subscribe((event) => {
        if (event instanceof RoutesRecognized) {
          // fix from Xinjie, this will solve the problem when one redirect item is not highlighted
          const route = event.urlAfterRedirects ? event.urlAfterRedirects : event.url;
          this._verifySelectedState(route);
          this._verifyActiveSate(route);
        }
      })
    );
  }

  private _subscribeUnselectItem(): void {
    this._subscription.add(
      this.service.subMenuCloseEvent$
        .pipe(withLatestFrom(this.service.subMenuParentItem$))
        .subscribe(([, subMenuParentItem]) => {
          if (subMenuParentItem === this.item) {
            this._selected$$.next(false);
          }
        })
    );

    this._subscription.add(
      this.service.subMenuParentItem$.pipe(filter((item) => item !== null)).subscribe((item: SidebarItem) => {
        if (this.item !== item) {
          this._selected$$.next(false);
        }
      })
    );
  }
}
