<div class="submenu-header">
  <div class="submenu-title" [class.withBorder]="!hasSearchBar">
    <div id="title">
      <i aria-hidden="true" class="{{ titleIcon }} title-icon"></i>
      <span id="title-label">
        {{ titleLabel | transloco }}
      </span>
    </div>
    <button
      class="icon-close"
      (click)="closeSubmenu()"
      aria-label="close menu"
    ></button>
  </div>
</div>
<div class="submenu-body">
  <div id="searchbox-wrapper" *ngIf="hasSearchBar">
    <i aria-hidden="true" class="icon-lp_search"></i>
    <input
      class="submenu-header-searchbox"
      type="text"
      placeholder="Search"
      tabindex="0"
      *ngIf="hasSearchBar"
      ngModel
      (ngModelChange)="filterItems($event)"
    />
  </div>
  <pulse-sidebar-actionlist
    *ngIf="filteredItems$ | async as filteredItems; else empty"
    [actions]="filteredItems"
  ></pulse-sidebar-actionlist>
  <ng-template #empty>
    <div class="submenu-body--empty">
      <span class="submenu-body-text">no item available</span>
    </div>
  </ng-template>
</div>
