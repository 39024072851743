import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastyMessageType } from '../../enums';
import { ToastyMessageIntern } from '../../interfaces/toasty-message-intern.interface';
import { ToastyService } from '../../services/toasty/toasty.service';

@Component({
  selector: 'pulse-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements OnInit {
  @Input() message: ToastyMessageIntern;
  public readonly toastyMessageType = ToastyMessageType;

  private _dismissed$$ = new BehaviorSubject(false);
  public dismissed$ = this._dismissed$$.asObservable();
  public icon = '';
  constructor(public toastyService: ToastyService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.message.dismissed) {
      this._dismissed$$.next(this.message.dismissed);
    }
    switch (this.message.type) {
      case ToastyMessageType.Default:
        this.icon = 'icon-check-mark2';
        break;
      case ToastyMessageType.Error:
      case ToastyMessageType.Warning:
        this.icon = 'icon-disclaimer';
        break;
      case ToastyMessageType.Success:
        this.icon = 'icon-check-mark2';
        break;
      default:
        this.icon = 'icon-lp_information';
        break;
    }
    if (this.message.timeout) {
      setTimeout(() => this.dismiss(this.message.id), this.message.timeout);
    }
  }

  public dismiss(id: number): void {
    this._dismissed$$.next(true);
    setTimeout(() => {
      this.toastyService.dismissMessage(id);
    }, 1000);
  }
}
