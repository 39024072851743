import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ICONS, Language } from '@pulse/shared/models';
import { SidebarActionItem, SidebarItem, SidebarItemNavigationType } from '@pulse/ui/sidebar-nav';
import { ToastyService } from '@pulse/ui/toasty';
import {
  AwsLoginFacadeService,
  AwsLoginSchedulerService,
  InfrastructureState,
  selectPermissions,
} from '@pulse/util/infrastructure-state';
import { getIsoCodeTranslation } from '@pulse/util/localization';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ROUTE_PATHS } from '../../const';

const LANGUAGES = [Language.EN, Language.DE];

@Component({
  selector: 'pulse-infrastructure',
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfrastructureComponent implements OnInit {
  private readonly _icons = ICONS;
  private readonly _languageItems = LANGUAGES.map(
    (lang): SidebarActionItem => ({
      title: getIsoCodeTranslation(lang),
      isSelected$: new BehaviorSubject<boolean>(this._translationService.getActiveLang() === lang),
      langCode: lang,
      icon: ICONS.translate,
      function: (actionItem: SidebarActionItem): void => {
        if (actionItem.langCode) {
          this._translationService.setActiveLang(actionItem.langCode);
          actionItem.isSelected$.next(true);
          for (const languageItem of this._languageItems) {
            languageItem.isSelected$?.next(actionItem.langCode === lang);
          }
        }
      },
    })
  );

  public collapse: boolean;

  public itemsMiddle$ = this._store$.select(selectPermissions).pipe(
    map((permissions) => {
      const items: SidebarItem[] = [];
      if (permissions.includes('HomeRoute')) {
        items.push({
          directPath: `/${ROUTE_PATHS.module}/${ROUTE_PATHS.home}`,
          path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.home}`,
          navigationType: SidebarItemNavigationType.Path,
          data: { navigation: 'Home', checkChildren: false, icon: this._icons.home },
        });
      }
      //These features are always available to the user
      items.push(
        {
          directPath: `/${ROUTE_PATHS.module}/${ROUTE_PATHS.login}`,
          path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.login}`,
          navigationType: SidebarItemNavigationType.Path,
          data: { navigation: 'Login', checkChildren: false, icon: this._icons.person },
        },
        {
          path: `onboarding`,
          directPath: `/${ROUTE_PATHS.module}/${ROUTE_PATHS.developer}/onboarding`,
          navigationType: SidebarItemNavigationType.Path,
          data: { navigation: 'Onboarding', icon: this._icons.check },
        }
      );

      if (permissions.includes('TenantConfigRoute')) {
        items.push({
          directPath: `/${ROUTE_PATHS.module}/${ROUTE_PATHS.tenantConfig}`,
          path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.tenantConfig}`,
          navigationType: SidebarItemNavigationType.Path,
          data: { navigation: 'Tenant Config', checkChildren: false, icon: this._icons.donut_chart },
        });
      }
      if (permissions.includes('DeveloperRoute')) {
        items.push({
          directPath: `/${ROUTE_PATHS.module}/${ROUTE_PATHS.developer}`,
          path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.developer}`,
          navigationType: SidebarItemNavigationType.Path,
          data: { navigation: 'Developer', icon: this._icons.add_person },
        });
      }
      if (permissions.includes('IdpRoute')) {
        items.push({
          directPath: `/${ROUTE_PATHS.module}/${ROUTE_PATHS.keycloak}`,
          path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.keycloak}`,
          navigationType: SidebarItemNavigationType.Path,
          data: { navigation: 'Keycloak', icon: this._icons.key },
        });
      }
      if (permissions.includes('PipelinesRoute')) {
        items.push({
          directPath: `/${ROUTE_PATHS.module}/${ROUTE_PATHS.pipelines}`,
          path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.tenantConfig}`,
          navigationType: SidebarItemNavigationType.Path,
          data: { navigation: 'Pipelines', icon: this._icons.analyze },
        });
      }
      if (permissions.includes('ResourceTypeDefinition')) {
        //if(permissions.includes('ResourceTypeDefinitionRoute')) {
        items.push({
          directPath: `/${ROUTE_PATHS.module}/${ROUTE_PATHS.resourceTypeDefinition}`,
          path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.resourceTypeDefinition}`,
          navigationType: SidebarItemNavigationType.Path,
          data: { navigation: 'Resource Type Definition', icon: this._icons.donut_chart },
        });
      }
      // }
      return items;
    })
  );

  public readonly itemsTop$ = this._awsLoginFacade.userName$.pipe(
    map((userName): SidebarItem[] =>
      userName
        ? [
            {
              navigationType: SidebarItemNavigationType.Noaction,
              data: { navigation: userName },
            },
          ]
        : []
    )
  );

  public readonly itemsBottom: SidebarItem[] = [
    /* {
      navigationType: SidebarItemNavigationType.Submenu,
      title: 'Language',
      icon: ICONS.world,
      // eslint-disable-next-line rxjs/finnish
      subMenuItemSource: new BehaviorSubject<SidebarActionItem[]>(this._languageItems),
      canSearchChildren: false,
    }, */
  ];

  constructor(
    private readonly _awsLoginFacade: AwsLoginFacadeService,
    private readonly _awsLoginSchedulerService: AwsLoginSchedulerService,
    private readonly _translationService: TranslocoService,
    private readonly _router: Router,
    private _store$: Store<InfrastructureState>
  ) {}
  ngOnInit(): void {
    this._router.navigate([`/${ROUTE_PATHS.module}/${ROUTE_PATHS.login}`]);
  }
}
