import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import {
  translocoConfig,
  TranslocoModule,
  TranslocoService,
  TRANSLOCO_CONFIG,
  TRANSLOCO_FALLBACK_STRATEGY,
  TRANSLOCO_LOADER,
} from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EnvironmentModule } from '@pulse/util/environment';
import { AwsLoginEffects, awsLoginReducer, UtilInfrastructureStateModule } from '@pulse/util/infrastructure-state';
import {
  TranslationLoadFallbackService,
  TranslocoCustomService,
  TranslocoHttpLoaderService,
  UtilLocalizationModule,
} from '@pulse/util/localization';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { SidebarNavModule } from '@pulse/ui/sidebar-nav';
import { HomeComponent, InfrastructureComponent } from './components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastyModule } from '@pulse/ui/toasty';

@NgModule({
  declarations: [AppComponent, HomeComponent, InfrastructureComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SidebarNavModule,
    TranslocoModule,
    UtilLocalizationModule,
    HttpClientModule,
    ToastyModule,
    UtilInfrastructureStateModule,
    EnvironmentModule.forRoot(environment),
    StoreModule.forRoot({ awsLogin: awsLoginReducer }),
    EffectsModule.forRoot([AwsLoginEffects]),
    StoreDevtoolsModule.instrument({}),
  ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en'],
        defaultLang: 'en',
        fallbackLang: 'en',
        missingHandler: {
          useFallbackTranslation: true,
          logMissingKey: false,
        },
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoaderService },
    { provide: TRANSLOCO_FALLBACK_STRATEGY, useClass: TranslationLoadFallbackService },
    { provide: TranslocoService, useClass: TranslocoCustomService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
