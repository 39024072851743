export const LANGUAGES: { [key: string]: string } = {
  ar: 'العربية | Arabic',
  cs: 'Čeština | Czech',
  da: 'Dansk | Danish',
  de: 'Deutsch | German',
  'de-at': 'Deutsch (Österreich)',
  'de-de': 'Deutsch (Deutschland)',
  'de-ch': 'Deutsch (Schweiz)',
  en: 'English',
  es: 'Español | Spanish',
  fr: 'Français | French',
  hr: 'Hrvatski | Croatian',
  hu: 'magyar | Hungarian',
  it: 'Italiano | Italian',
  no: 'Norsk | Norwegian',
  pt: 'Português | Portuguese',
  'pt-br': 'Português (brasil) | Portuguese - Brazil',
  ru: 'Pусский | Russian',
  sv: 'Svenska | Swedish',
  tr: 'Türkçe | Turkish',
  zh: '中文（简体）| Chinese( simplified)',
  'fr-ca': 'Français (canadien) | French (canadian)',
  th: 'ไทย | Thai',
  nl: 'Nederlands | Dutch',
  id: 'Bahasa Indonesia | Indonesian',
  he: 'עברית | Hebrew',
  ja: '日本語 | Japanese',
  ko: '한국어 | Korean',
  pl: 'Język polski | Polish',
  ur: 'اردو | Urdu',
  gu: 'ગુજરાતી | Gujarati',
  hi: 'हिन्दी | Hindi',
  mr: 'मराठी | Marathi',
  'zh-ch': '中文（繁体）| Chinese( traditional)',
  ro: 'Română | Romanian',
  rs: 'Srpski | Serbian',
  key: 'Translation-Key',
};

export function getIsoCodeTranslation(value: string): string {
  const language = LANGUAGES[value.toLowerCase()];
  return language ? language : value;
}
