import { Injectable } from '@angular/core';
import { HashMap } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { ToastyMessageType } from '../../enums';
import { ToastyMessageIntern } from '../../interfaces/toasty-message-intern.interface';
import { ToastyMessage } from '../../interfaces/toasty-message.interface';

@Injectable({
  providedIn: 'root',
})
export class ToastyService {
  private _lastId = 0;
  private _messages$$ = new BehaviorSubject<ToastyMessageIntern[]>([]);

  public messages$ = this._messages$$.asObservable();

  constructor() {}

  public message(text: string, title?: string, textParams?: HashMap): void {
    this.showMessage({
      type: ToastyMessageType.Default,
      title: title ?? 'Message',
      content: text,
      timeout: 10000,
      contentParams: textParams,
    });
  }

  public info(text: string, title?: string, textParams?: HashMap): void {
    this.showMessage({
      content: text,
      type: ToastyMessageType.Info,
      title: title ?? 'Info',
      timeout: 10000,
      contentParams: textParams,
    });
  }

  public save(title?: string): void {
    this.showMessage({
      content: '' + (this._lastId + 1),
      type: ToastyMessageType.Success,
      title: title ?? 'Save',
      timeout: 2000,
    });
  }

  public warning(text: string, title?: string, textParams?: HashMap): void {
    this.showMessage({
      content: text,
      type: ToastyMessageType.Warning,
      title: title ?? 'Warning',
      timeout: 10000,
      contentParams: textParams,
    });
  }

  public error(text: string, title?: string, textParams?: HashMap): void {
    this.showMessage({
      content: text,
      type: ToastyMessageType.Error,
      title: title ?? 'Error',
      timeout: undefined,
      contentParams: textParams,
    });
  }

  public showMessage(msg: ToastyMessage): void {
    const msgIntern: ToastyMessageIntern = {
      id: ++this._lastId,
      ...msg,
      onAdd: () => this._onAdd({ ...msg }),
      onRemove: () => this._onRemove({ ...msg }),
      dismissed: false,
    };
    msgIntern.onAdd();
    this._addMessage(msgIntern);
  }

  public dismissMessage(id: number): void {
    const messages = this._messages$$.getValue();
    const findMessage = messages.find((m) => m.id === id);
    if (findMessage) {
      if (findMessage.onRemove) {
        findMessage.onRemove();
      }
    }
    this._messages$$.next(messages.filter((m) => m.id !== id));
  }

  private _addMessage(msgIntern: ToastyMessageIntern): void {
    const messages = this._messages$$.getValue();
    const findMessage = messages.find(
      (m) => m.content === msgIntern.content && m.title === msgIntern.title && !m.dismissed
    );
    if (!findMessage) {
      messages.push(msgIntern);
    }
    this._messages$$.next(messages);
  }

  private _onAdd(msg: ToastyMessage): void {
    //
  }

  private _onRemove(msg: ToastyMessage): void {
    //
  }
}
