import { createAction, props } from '@ngrx/store';
import { ResourceDTO, ResourceFull, UserErrorResponse } from '@pulse/api/core';

export const selectResource = createAction('[Resource] Select Resource', props<{ resourceId: number | undefined }>());
export const disableResources = createAction('[Resource] Disable Resources', props<{ resourceIds: number[] }>());
export const enableResources = createAction('[Resource] Enable Resources', props<{ resourceIds: number[] }>());

export const loadResource = createAction(
  '[Resource] Load Resource',
  props<{
    resourceId: number;
    afterDoneCallback?: () => void;
    onErrorCallback?: (userError: UserErrorResponse) => void;
  }>()
);
export const reloadCurrentResource = createAction(
  '[Resource] Reload current Resource',
  props<{
    afterDoneCallback?: () => void;
    onErrorCallback?: (userError: UserErrorResponse) => void;
  }>()
);
export const loadResourceFull = createAction(
  '[Resource] Load Resource Full',
  props<{
    resourceId: number;
    afterDoneCallback?: () => void;
    onErrorCallback?: (userError: UserErrorResponse) => void;
  }>()
);
export const loadCurrentResourceAsFull = createAction(
  '[Resource] Load current Resource as Full',
  props<{
    afterDoneCallback?: () => void;
    onErrorCallback?: (userError: UserErrorResponse) => void;
  }>()
);
export const loadResourceFullSuccess = createAction(
  '[Resource] Load Resource Full Success',
  props<{ resource: ResourceFull }>()
);
export const loadResourceSuccess = createAction('[Resource] Load Resource Success', props<{ resource: ResourceDTO }>());
export const loadResourceFailure = createAction(
  '[Resource] Load Resource Failure',
  props<{ error: UserErrorResponse }>()
);

export const updateResource = createAction(
  '[Resource] Update Resource',
  props<{
    resourceId: number;
    resource: ResourceFull;
    afterDoneCallback?: () => void;
    onErrorCallback?: (userError: UserErrorResponse) => void;
  }>()
);
export const updateResourceSuccess = createAction(
  '[Resource] Update Resource Success',
  props<{ resource: ResourceFull }>()
);
export const updateResourceFailure = createAction(
  '[Resource] Update Resource Failure',
  props<{ error: UserErrorResponse }>()
);

export const createResource = createAction(
  '[Resource] Create Resource',
  props<{
    resource: ResourceFull;
    afterDoneCallback?: (resource: ResourceDTO) => void;
    onErrorCallback?: (userError: UserErrorResponse) => void;
  }>()
);
export const createResourceSuccess = createAction(
  '[Resource] Create Resource Success',
  props<{ resource: ResourceFull }>()
);
export const createResourceFailure = createAction(
  '[Resource] Create Resource Failure',
  props<{ error: UserErrorResponse }>()
);

export const loadResources = createAction(
  '[Resource] Load Resources',
  props<{
    resourceTypeIds: number[];
    afterDoneCallback?: (resources: ResourceDTO[]) => void;
    onErrorCallback?: (userError: UserErrorResponse) => void;
  }>()
);
export const loadResourcesSuccess = createAction(
  '[Resource] Load Resources Success',
  props<{ resources: ResourceDTO[] }>()
);
export const loadResourcesFailure = createAction(
  '[Resource] Load Resources Failure',
  props<{ error: UserErrorResponse }>()
);
