import { Inject, Injectable, Optional } from '@angular/core';
import { HttpCacheClient } from '@pulse/api/http-cache';
import { API_BASE_URL, ClientV1 } from './api-client-v1';

@Injectable()
export class ClientV1Cached extends ClientV1 {
  constructor(httpClient: HttpCacheClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(httpClient, baseUrl);
  }
}
