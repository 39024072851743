import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminPrivilegeEnum } from '@pulse/shared/models';
import { InitFeatureKey, InitState, resourceTypeActionsAdapter } from '../reducers/init.reducer';

const { selectAll } = resourceTypeActionsAdapter.getSelectors();
export const getInitState = createFeatureSelector<InitState>(InitFeatureKey);

export const initLoading = createSelector(getInitState, (state: InitState) => state.loading);

export const isGlobalAdmin = createSelector(getInitState, (state: InitState) => state.isGlobalAdmin);

export const initError = createSelector(getInitState, (state: InitState) => state.error);

export const isUserAdmin = createSelector(
  getInitState,
  (state: InitState) => state.adminPrivilege.indexOf(AdminPrivilegeEnum.UserAdmin) !== -1
);

export const isUserAdminLight = createSelector(
  getInitState,
  (state: InitState) => state.adminPrivilege.indexOf(AdminPrivilegeEnum.UserAdminLight) !== -1
);

export const getAdminPrivileges = createSelector(getInitState, (state: InitState) => state.adminPrivilege);

export const getResourceTypes = createSelector(getInitState, (state: InitState) =>
  selectAll(state.resourceTypeActions)
);
