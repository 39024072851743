import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { IEnvironment, ILink } from '../../interface';
import { PULSE_ENVIRONMENT } from '../../tokens';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(
    @Inject(PULSE_ENVIRONMENT) private readonly _env: IEnvironment,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public get(): IEnvironment {
    return this._env;
  }

  public getTitle(): string {
    return this._env.title ?? 'Pulse.Cloud';
  }

  public getTenant(): string {
    const { hostname } = this._document.location;
    if (this._env.tenant) {
      return this._env.tenant;
    }
    return hostname.split('.')[0];
  }

  public getIdpConfig(): AuthConfig {
    return this._env.authconfig;
  }

  public getServer(serverName: string): string {
    const { hostname } = this._document.location;

    if (this._env.server[hostname] && this._env.server[hostname][serverName]) {
      return this._env.server[hostname][serverName];
    }

    return `${this._env.server['default'][serverName]}`;
  }

  public getSidenavLogoLink(): ILink | undefined {
    return this._env.sidenavLogoLink;
  }
}
