import { createSelector } from '@ngrx/store';
import { InfrastructureState } from '../../InfrastructureState';
import { AWSLoginState } from '../reducers/awslogin.reducer';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const selectAWSLoginState = (state: InfrastructureState) => state.awsLogin;

export const selectAwsLoginState = createSelector(selectAWSLoginState, (state: AWSLoginState) => state);

export const selectAccountConfigs = createSelector(selectAWSLoginState, (state: AWSLoginState) => state.accountConfigs);

export const selectPulseAccountProfileCredentials = createSelector(
  selectAWSLoginState,
  (state: AWSLoginState) => state.pulseAccountCredentials
);

export const selectPulseAppAccountConfig = createSelector(selectAWSLoginState, (state: AWSLoginState) =>
  state.accountConfigs.find((accountConfig) => accountConfig.AccountName === 'PulseApp')
);

export const selectPulseAppAccountCredentials = createSelector(
  selectAWSLoginState,
  (state: AWSLoginState) =>
    state.accountConfigs.find((accountConfig) => accountConfig.AccountName === 'PulseApp')?.Credentials
);
export const selectPulseDatalakeAccountCredentials = createSelector(
  selectAWSLoginState,
  (state: AWSLoginState) =>
    state.accountConfigs.find((accountConfig) => accountConfig.AccountName === 'PulseDatalake')?.Credentials
);

export const selectIsMfaLoggedIn = createSelector(selectAWSLoginState, (state: AWSLoginState) => state.isMfaLoggedIn);

export const selectIsMfaPanelOpen = createSelector(selectAWSLoginState, (state: AWSLoginState) => state.mfaPanelOpen);
export const selectPulseAppCredentials = createSelector(
  selectAWSLoginState,
  (state: AWSLoginState) => state.accountConfigs.find((config) => config.AccountName === 'PulseApp')?.Credentials
);

export const selectMfaLoginError = createSelector(selectAWSLoginState, (state: AWSLoginState) => state.mfaError);

export const selectCredentialsFile = createSelector(
  selectAWSLoginState,
  (state: AWSLoginState) => state.awsCredentialsFile
);

export const selectMfaCredentials = createSelector(selectAWSLoginState, (state: AWSLoginState) => state.mfaCredentials);

export const selectUserName = createSelector(selectAWSLoginState, (state: AWSLoginState) => state.userName);

export const selectMfaExiration = createSelector(selectAWSLoginState, (state: AWSLoginState) => state.mfaExpiry);

//Selector that returns all the permissions for the current user. All keys of state.permission have to be checked if their value contains a group name that is also part of state.groups
export const selectPermissions = createSelector(selectAWSLoginState, (state: AWSLoginState) =>
  Object.keys(state.permissions).filter((key) =>
    state.permissions[key].some((group) => state.iamGroups.map((iamGroup) => iamGroup.GroupName).includes(group))
  )
);

//Selector that takes a permission key and returns true if the user has that permission
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const selectHasPermission = (permission: string) =>
  createSelector(selectPermissions, (permissions) => permissions.includes(permission));

//Select the current user's IAM groups
export const selectIamGroups = createSelector(selectAWSLoginState, (state: AWSLoginState) => state.iamGroups);

//Selector that returns an object with all the values needed to refresh the PulseApp account profile
//The values are: MFA Credentials, Account Config for PulseApp Account, UserName, MfaExpiration and User IAM Groups
export const selectValuesForPulseAppAccountProfileRefresh = createSelector(
  selectAWSLoginState,
  (state: AWSLoginState) => ({
    mfaCredentials: state.mfaCredentials,
    pulseAppAccountConfig: state.accountConfigs.find((config) => config.AccountName === 'PulseApp'),
    userName: state.userName,
    mfaExpiration: state.mfaExpiry,
    iamGroups: state.iamGroups,
  })
);

//Selector for object that contains all values for AWS Console Sign In URL creation
export const selectValuesForAwsConsoleSignInUrlCreation = createSelector(
  selectAWSLoginState,
  (state: AWSLoginState) => ({
    awsConsoleCredentials: state.awsConsoleCredentials,
    awsConsoleCredentialsExpiry: state.awsConsoleCredentialsExpiry,
  })
);

//Selector for AWS Console MFA Panel Open
export const selectAwsConsoleMfaPanelOpen = createSelector(
  selectAWSLoginState,
  (state: AWSLoginState) => state.awsConsoleMfaPanelOpen
);

//Selector for AWS Console MFA Error
export const selectAwsConsoleMfaError = createSelector(
  selectAWSLoginState,
  (state: AWSLoginState) => state.awsConsoleCredentialsError
);

export const selectAwsCredentialsFileError = createSelector(
  selectAWSLoginState,
  (state: AWSLoginState) => state.awsCredentialsFileError
);

export const selectQueuedAwsConsoleUrl = createSelector(
  selectAWSLoginState,
  (state: AWSLoginState) => state.queuedAwsConsoleUrl
);
