<pulse-sidebar-nav
  logoImage="assets/images/siemens-logo.svg"
  brandImage="assets/images/Pulse_Logo_B&W_NoShadow.svg"
  brandLink="https://pulse.cloud"
  [link]="'/'"
  [itemTop]="itemsTop$ | async"
  [itemMiddle]="itemsMiddle$ | async"
  [itemBottom]="itemsBottom"
  [title]="'Pulse.Cloud Infrastructure'"
  (collapse)="collapse = $event"
  [class.collapse]="collapse"
></pulse-sidebar-nav>
<div class="main">
  <div class="main__content"><router-outlet></router-outlet></div>
</div>
