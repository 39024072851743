import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SidebarItem } from '../../interfaces';

@Component({
  selector: 'pulse-sidebar-actionlist',
  templateUrl: './sidebar-actionlist.component.html',
  styleUrls: ['./sidebar-actionlist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarActionlistComponent {
  @Input() public readonly actions: SidebarItem[] = [];
}
