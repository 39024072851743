import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SidebarItem } from '../../interfaces';
import { SidebarItemNavigationType } from '../../enums';
import { SidebarService } from '../../services';

@Component({
  selector: 'pulse-sidebar-itemlist',
  templateUrl: './sidebar-itemlist.component.html',
  styleUrls: ['./sidebar-itemlist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarItemlistComponent {
  @Input() public items: SidebarItem[];
  @Input() public icon = '';
  @Input() public path: string;
  @Input() public childrenContainerId: string;
  @Output() public itemClick = new EventEmitter<unknown>();

  public itemNavigationType = SidebarItemNavigationType;

  constructor(public readonly service: SidebarService) {}

  public emitClick(child: SidebarItem, fromChild = false): void {
    if (!this.service.hasChildrenData(child) || fromChild) {
      this.itemClick.emit();
    }
  }

  public trackByPath(_index: number, item: SidebarItem): string {
    return item.path;
  }
}
