import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserFeatureKey, UserState } from '../reducers/user.reducer';

export const getUserState = createFeatureSelector<UserState>(UserFeatureKey);

export const getUserClaim = createSelector(getUserState, (state: UserState) => state.userClaim);
export const getUserProxy = createSelector(getUserState, (state: UserState) => state.userProxy);
export const getIsProxy = createSelector(getUserProxy, (userProxy) => !!userProxy);
export const getDisplayName = createSelector(getUserClaim, getUserProxy, (userClaim, userProxy) =>
  userProxy
    ? `${userProxy.firstName ?? ''} ${userProxy.lastName ?? ''} (Proxy)`
    : userClaim
    ? userClaim.displayName === ''
      ? 'Guest'
      : userClaim.displayName
    : 'Guest'
);
