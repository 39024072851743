<aside
  class="sidebar"
  *ngIf="{ collapse: service.collapse$ | async } as local"
  [class.collapse]="local.collapse"
>
  <nav class="sidebar-body" role="navigation" aria-label="Main Navigation">
    <div class="sidebar-header">
      <a class="skip-to-content-link" [href]="skipLinkPath">Skip to content</a>
      <a
        *ngIf="logoLink"
        [class.invisible]="local.collapse"
        class="logo-wrapper"
        [href]="logoLink"
        [attr.target]="linkTarget ?? null"
      >
        <ng-container [ngTemplateOutlet]="logoImageTemplate"></ng-container>
      </a>
      <div
        *ngIf="!logoLink"
        [class.invisible]="local.collapse"
        class="logo-wrapper"
      >
        <ng-container [ngTemplateOutlet]="logoImageTemplate"></ng-container>
      </div>
      <button
        aria-label="expand or shrink navbar"
        class="sidebar-toggle icon-burger"
        tabindex="0"
        (click)="toggle(local.collapse)"
        [attr.aria-expanded]="local.collapse"
      ></button>
    </div>
    <div
      class="sidebar-usersection"
      *ngIf="
        service.navigationItemsTop &&
        service.navigationItemsTop[0] &&
        service.navigationItemsTop[0]?.title !== 'anonymous@anonymous.de'
      "
    >
      <span class="welcome-text collapse-hidden">
        {{ 'pulse.sidebar.welcome' | transloco }}
      </span>
      <pulse-sidebar-itemlist
        [items]="[service.navigationItemsTop[0]]"
        class="sidebar-navigation"
      ></pulse-sidebar-itemlist>
    </div>
    <div class="sidebar-content">
      <a class="sidebar-title" [routerLink]="[link]">
        <i *ngIf="local.collapse" class="icon-home" aria-hidden="true"></i>
        <h3 class="sidebar-title-text collapse-hidden">{{ title }}</h3>
      </a>

      <pulse-sidebar-itemlist
        [items]="
          service.navigationItemsTop && service.navigationItemsTop[1]
            ? [service.navigationItemsTop[1]]
            : []
        "
        class="sidebar-navigation"
        *ngIf="service?.navigationItemsTop?.length ?? 0 >= 1"
      ></pulse-sidebar-itemlist>

      <pulse-sidebar-itemlist
        [items]="service.navigationItemsMiddle ?? []"
        [path]="link ?? ''"
        (itemClick)="onItemClick(local.collapse)"
        class="sidebar-navigation sidebar-navigation-middle"
      ></pulse-sidebar-itemlist>

      <pulse-sidebar-itemlist
        [items]="service.navigationItemsBottom ?? []"
        [path]="link ?? ''"
        (itemClick)="onItemClick(local.collapse)"
        class="sidebar-bottom"
      ></pulse-sidebar-itemlist>
    </div>
    <a class="sidebar-brand" [href]="brandLink">
      <img
        width="34px"
        height="40px"
        [src]="brandImage"
        alt="pulse.cloud logo"
      />
      <span id="powered-by" class="collapse-hidden">
        <span>Powered by</span>
        <span>Pulse.Cloud</span>
      </span>
    </a>
  </nav>
</aside>

<ng-container *ngIf="service.subMenuItems$ | async as subMenuItems">
  <pulse-sidebar-submenu
    *ngIf="service.isSubMenuVisible"
    [itemList]="subMenuItems"
    [titleLabel]="(service.subMenuParentItem$ | async)?.title"
    [titleIcon]="(service.subMenuParentItem$ | async)?.icon"
    [class.hidden]="!service.isSubMenuVisible"
    [hasSearchBar]="
      (service.subMenuParentItem$ | async)?.canSearchChildren ?? false
    "
    (closeClick)="onSubMenuClose()"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
  ></pulse-sidebar-submenu>
</ng-container>

<ng-template #logoImageTemplate>
  <img class="logo" [src]="logoImage" alt="Logo" />
</ng-template>
