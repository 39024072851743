export const ROUTE_PATHS = {
  infrastructure: 'infrastructure',
  module: 'project',
  home: 'home',
  login: 'login',
  tenantConfig: 'tenantConfig',
  developer: 'developer',
  pipelines: 'pipelines',
  keycloak: 'keycloak',
  resourceTypeDefinition: 'resource-type-definition',
};
