import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { combineKeys, R2rFeatureKey, r2rPropertyAdapter, R2rPropertyState } from '../reducers/r2r-property.reducer';

export const getR2rPropertyState = createFeatureSelector<R2rPropertyState>(R2rFeatureKey);

const { selectEntities } = r2rPropertyAdapter.getSelectors(getR2rPropertyState);

export const getR2rProperty = (
  parentId: number,
  resourceId: number
): MemoizedSelector<
  object,
  { [key: string]: string } | undefined,
  DefaultProjectorFn<{ [key: string]: string } | undefined>
> => createSelector(selectEntities, (entities) => entities[combineKeys(parentId, resourceId)]?.properties);
