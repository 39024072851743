import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Category, ResourceTypeAction, User } from '@pulse/api/core';
import * as InitActions from '../actions/init.actions';

export const InitFeatureKey = 'init';

export type ResourceTypeActionState = EntityState<ResourceTypeAction>;
export type CategoryState = EntityState<Category>;

export interface InitState {
  loading: boolean;
  isGlobalAdmin: boolean;
  user: User | undefined;
  resourceTypeActions: ResourceTypeActionState;
  categories: CategoryState;
  adminPrivilege: number[];
  error: HttpErrorResponse | undefined;
}

export const resourceTypeActionsAdapter = createEntityAdapter<ResourceTypeAction>();
export const categoriesAdapter = createEntityAdapter<Category>();

export const initialInitState: InitState = {
  isGlobalAdmin: false,
  loading: true,
  error: undefined,

  user: undefined,
  adminPrivilege: [],
  resourceTypeActions: resourceTypeActionsAdapter.getInitialState(),
  categories: categoriesAdapter.getInitialState(),
};

const createInitReducer = createReducer(
  initialInitState,

  on(InitActions.loadInit, (state) => ({
    ...state,
    loading: true,
    error: undefined,
  })),

  on(InitActions.loadInitSuccess, (state, { init }) => ({
    ...state,
    loading: false,
    isGlobalAdmin: init.isGlobalAdmin,
    user: init.user,
    adminPrivilege: init.adminPrivilege ?? [],
    resourceTypeActions: resourceTypeActionsAdapter.setAll(init.resourceTypes ?? [], state.resourceTypeActions),
    categories: categoriesAdapter.setAll(init.categories ?? [], state.categories),
  })),

  on(InitActions.loadInitFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

export function initReducer(state: InitState | undefined, action: Action): InitState {
  return createInitReducer(state, action);
}
