import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { interval, Subscription, timer } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  loadAwsCredentialsFile,
  logoutMfa,
  refreshNonPulseAppAccountCredentials,
  refreshPulseAppCredentials,
  removeConsoleSigninUrl,
} from '../+state/actions/awslogin.actions';
import { selectAccountConfigs, selectIsMfaLoggedIn, selectMfaExiration } from '../+state/selectors/awslogin.selectors';
import { InfrastructureState } from '../InfrastructureState';

@Injectable({
  providedIn: 'root',
})
export class AwsLoginSchedulerService {
  private _timerSubscription = new Subscription();
  constructor(private _store$: Store<InfrastructureState>) {
    this._initialize();
  }

  private _initialize(): void {
    console.log('Initializing AWS Login Scheduler Service');
    this._store$.dispatch(loadAwsCredentialsFile());
    const accountConfigs$ = this._store$.pipe(select(selectAccountConfigs));
    const mfaExpiration$ = this._store$.pipe(select(selectMfaExiration));
    const isMfaLoggedIn$ = this._store$.pipe(select(selectIsMfaLoggedIn));
    const oneMinuteInverval$ = interval(60000);
    const accountConfigsEveryMinute$ = oneMinuteInverval$.pipe(
      withLatestFrom(accountConfigs$, mfaExpiration$, isMfaLoggedIn$),
      map(([_, accountConfigs, mfaExpiration, isMfaLoggedIn]) => ({ accountConfigs, mfaExpiration, isMfaLoggedIn }))
    );

    this._timerSubscription.add(
      accountConfigsEveryMinute$.subscribe({
        next: (result) => {
          //MFA is expired, logout MFA
          if (result.mfaExpiration && result.mfaExpiration < new Date()) {
            this._store$.dispatch(logoutMfa());
            return; //no need to refresh credentials if mfa is expired
          }
          if (result.isMfaLoggedIn) {
            result.accountConfigs?.forEach((accountConfig) => {
              //accountConfig.CredentialExpirationDate is less than 2 minutes in the future
              if (
                accountConfig.CredentialExpirationDate &&
                accountConfig.CredentialExpirationDate.getTime() - new Date().getTime() < 2 * 60 * 1000
              ) {
                console.log(
                  `Refreshing credentials for ${accountConfig.ProfileName} because they will expire in less than 2 minutes`
                );
                if (accountConfig.ProfileName === 'pulseapp') {
                  this._store$.dispatch(refreshPulseAppCredentials());
                } else {
                  this._store$.dispatch(refreshNonPulseAppAccountCredentials({ accountConfig: accountConfig }));
                }
              }

              //accountConfig.ConsoleSignInUrlCreationDate is more than 15 minutes ago, remove the url because it is expired
              if (
                accountConfig.ConsoleSignInUrlCreationDate &&
                new Date().getTime() - accountConfig.ConsoleSignInUrlCreationDate.getTime() > 15 * 60 * 1000
              ) {
                this._store$.dispatch(removeConsoleSigninUrl({ accountConfig: accountConfig }));
              }
            });
          }
        },
      })
    );
  }
}
