<span class="link-icon" *ngIf="icon || img" aria-hidden="true">
  <i *ngIf="icon" class="{{ icon }}"></i>
  <img *ngIf="img" [src]="img" [alt]="'image of ' + title" />
</span>
<span class="title">
  <span>{{ title | transloco }}</span>
  <span *ngIf="delegateUserName">
    <br />
    ({{ 'delegation.subtitle-keyword' | transloco }} {{ delegateUserName }})
  </span>
</span>
