import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pulse-sidebar-link',
  templateUrl: './sidebar-link.component.html',
  styleUrls: ['./sidebar-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarLinkComponent {
  @Input() public readonly title: string;
  @Input() public readonly delegateUserName: string;
  @Input() public readonly icon: string;
  @Input() public readonly img: string;
}
