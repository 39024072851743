import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SidebarItem } from '../../interfaces';
import { SidebarService } from '../../services';

@Component({
  selector: 'pulse-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarNavComponent implements OnInit, OnDestroy {
  @Input() public logoImage?: string;
  @Input() public logoLink?: string | null;
  @Input() public linkTarget?: string;

  @Input() public brandImage?: string;
  @Input() public brandLink?: string;

  @Input() public title?: string;
  @Input() public link?: string;

  @Input()
  public set projectId(id: number | null | undefined) {
    if (id !== null && id !== undefined) {
      this.service.setProjectId(id);
    }
  }

  @Input()
  public set itemTop(itemTop: SidebarItem[] | undefined) {
    this.service.navigationItemsTop = itemTop;
  }

  public get itemTop(): SidebarItem[] | undefined {
    return this.service.navigationItemsTop;
  }

  @Output() public collapse = new EventEmitter<boolean>();

  @Input()
  public set itemMiddle(itemMiddle: SidebarItem[] | undefined) {
    this.service.navigationItemsMiddle = itemMiddle;
  }

  public get itemMiddle(): SidebarItem[] | undefined {
    return this.service.navigationItemsMiddle;
  }

  @Input()
  public set itemBottom(itemBottom: SidebarItem[] | undefined) {
    this.service.navigationItemsBottom = itemBottom;
  }

  public get itemBottom(): SidebarItem[] | undefined {
    return this.service.navigationItemsBottom;
  }

  @Input()
  public readonly isMobile = false;

  public skipLinkPath = !this._router.url.endsWith('#main') ? `${this._router.url}#main` : this._router.url;

  private _subs$ = new Subscription();

  constructor(public readonly service: SidebarService, private _router: Router) {}

  ngOnInit(): void {
    this._subs$.add(
      this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        if (!this._router.url.endsWith('#main')) {
          this.skipLinkPath = `${this._router.url}#main`;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs$.unsubscribe();
  }

  public toggle(collapseState: boolean): void {
    this.service.collapseSidebar(!collapseState);
    this.collapse.emit(!collapseState);
  }

  public onItemClick(collapseState: boolean): void {
    if (this.isMobile) {
      this.toggle(collapseState);
    }
  }

  public onSubMenuClose(): void {
    this.service.isSubMenuVisible = false;
    this.service.emitSubMenuCloseEvent();
  }
}
