import { Injectable } from '@angular/core';
import { AvailableLangs, LangDefinition, TranslocoService } from '@ngneat/transloco';
import { Language } from '@pulse/shared/models';

@Injectable()
export class TranslocoCustomService extends TranslocoService {
  public override getAvailableLangs(): AvailableLangs {
    return super
      .getAvailableLangs()
      .map((language: string | LangDefinition) => language.toString())
      .filter((langKey) => this.getActiveLang() === Language.KEY || langKey !== Language.KEY);
  }
}
