import { createFeatureSelector, createSelector } from '@ngrx/store';
import { projectAdapter, ProjectFeatureKey, ProjectState } from '../reducers/project.reducer';
import { selectProjectId } from './router.selectors';

export const getProjectState = createFeatureSelector<ProjectState>(ProjectFeatureKey);

const { selectAll, selectEntities } = projectAdapter.getSelectors();

export const projectLoading = createSelector(getProjectState, (state: ProjectState) => state.loading);

export const projectError = createSelector(getProjectState, (state: ProjectState) => state.error);

export const getAllProjects = createSelector(getProjectState, (state: ProjectState) => selectAll(state));

export const getProjectEntities = createSelector(getProjectState, (state: ProjectState) => selectEntities(state));

export const getSelectedProject = createSelector(getProjectEntities, selectProjectId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);
