import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SidebarActionItem } from '../../interfaces';

@Component({
  selector: 'pulse-sidebar-action',
  templateUrl: './sidebar-action.component.html',
  styleUrls: ['./sidebar-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarActionComponent implements OnInit {
  @Input() public readonly action: SidebarActionItem;

  private readonly _showMore$$ = new BehaviorSubject<boolean>(false);
  public readonly showMore$ = this._showMore$$.pipe(distinctUntilChanged());

  public ngOnInit(): void {
    if (!this.action) {
      throw new Error(`SidebarActionComponent requires an [action] input property.`);
    }
  }

  public setShowMore(showMore: boolean): void {
    this._showMore$$.next(showMore);
  }

  public handleKeyboardEvent(event: Event): void {
    this.setShowMore(false);
    event.stopImmediatePropagation();
  }
}
