import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent, InfrastructureComponent } from './components';
import { ROUTE_PATHS } from './const';

const routes: Routes = [
  {
    path: '',
    component: InfrastructureComponent,
    children: [
      { path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.home}`, component: HomeComponent },
      {
        path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.login}`,
        loadChildren: () =>
          import('@pulse/feature/infrastructure-aws-login').then((m) => m.FeatureInfrastructureAwsLoginModule),
      },
      {
        path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.tenantConfig}`,
        loadChildren: () =>
          import('@pulse/feature/infrastructure-tenant').then((m) => m.FeatureInfrastructureTenantModule),
      },
      {
        path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.developer}`,
        loadChildren: () =>
          import('@pulse/feature/infrastructure-developer').then((m) => m.FeatureInfrastructureDeveloperModule),
      },
      {
        path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.pipelines}`,
        loadChildren: () =>
          import('@pulse/feature/infrastructure-pipeline').then((m) => m.FeatureInfrastructurePipelineModule),
      },
      {
        path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.keycloak}`,
        loadChildren: () =>
          import('@pulse/feature/infrastructure-keycloak').then((m) => m.FeatureInfrastructureKeycloakModule),
      },
      {
        path: `${ROUTE_PATHS.module}/${ROUTE_PATHS.resourceTypeDefinition}`,
        loadChildren: () =>
          import('@pulse/feature/infrastructure-resource-type-definition').then(
            (m) => m.FeatureInfrastructureResourceTypeDefinitionModule
          ),
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
    ],
  },
  { path: '', redirectTo: ROUTE_PATHS.infrastructure, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot([...routes], { useHash: true }), BrowserModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
