import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AwsLoginService } from '@pulse/api/infrastructure-aws';
import * as AwsLoginActions from '../actions/awslogin.actions';
import * as AwsLoginSelectors from '../selectors/awslogin.selectors';
import { from, merge, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mergeAll,
  mergeMap,
  subscribeOn,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { InfrastructureState } from '../../InfrastructureState';
import {
  selectAccountConfigs,
  selectCredentialsFile,
  selectMfaCredentials,
  selectPulseAccountProfileCredentials,
  selectPulseAppAccountConfig,
  selectPulseAppAccountCredentials,
  selectPulseAppCredentials,
  selectUserName,
} from '../selectors/awslogin.selectors';
import { Router } from '@angular/router';
import { ToastyService } from '@pulse/ui/toasty';

@Injectable()
export class AwsLoginEffects {
  constructor(
    private actions$: Actions,
    private _store$: Store<InfrastructureState>,
    private awsLogin: AwsLoginService,
    private _router: Router,
    private _toastyService: ToastyService
  ) {}
  public loginWithMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loginWithMfa),
      withLatestFrom(
        this._store$.pipe(select(selectPulseAccountProfileCredentials)),
        this._store$.pipe(select(selectUserName))
      ),
      switchMap(([action, pulseAccountProfileCredentials, userName]) =>
        from(this.awsLogin.loginMfa(action.mfaCode, pulseAccountProfileCredentials, userName)).pipe(
          tap(() => {
            this._store$.dispatch(AwsLoginActions.setLastMfaCode({ lastMfaCode: action.mfaCode }));
          }),
          tap(() => {
            this._store$.dispatch(AwsLoginActions.closeMfaPanel());
          }),
          map((result) =>
            AwsLoginActions.loginWithMfaSuccess({
              mfaCredentials: result.credentials,
              mfaExpiration: result.expiration,
            })
          ),
          catchError((error: unknown) => of(AwsLoginActions.loginWithMfaFailure({ error: (error as Error).message })))
        )
      )
    )
  );

  /**
   * After MFA Login, rotate pulseaccount credentials
   */
  public loginWithMfaSuccessRotatePulseAccountCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loginWithMfaSuccess),
      withLatestFrom(
        this._store$.pipe(select(selectPulseAccountProfileCredentials)),
        this._store$.pipe(select(selectUserName))
      ),
      switchMap(([action, pulseAccountProfileCredentials, userName]) =>
        from(
          this.awsLogin.rotatePulseAccountCredentials({
            pulseAccountProfileCredentials: pulseAccountProfileCredentials,
            pulseMfaCredentials: action.mfaCredentials,
            userName: userName,
          })
        ).pipe(
          map((result) =>
            AwsLoginActions.rotatePulseAccountCredentialsSuccess({
              credentials: result,
            })
          ),
          catchError((error: unknown) =>
            of(AwsLoginActions.rotatePulseAccountCredentialsFailure({ error: (error as Error).message }))
          )
        )
      )
    )
  );

  /**
   * After rotating pulse account credentials save the credentials file
   */
  public rotatePulseAccountCredentialsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.rotatePulseAccountCredentialsSuccess),
      map(() => AwsLoginActions.saveAwsCredentialsFile())
    )
  );

  /**
   * After the user has logged in with MFA, save the credentials file
   */
  public saveCredentialsFileAfterMfaLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loginWithMfaSuccess),
      withLatestFrom(this._store$.pipe(select(selectCredentialsFile))),
      map(() => AwsLoginActions.saveAwsCredentialsFile())
    )
  );

  public loginWithMfaError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loginWithMfaFailure),
      map(() => AwsLoginActions.openMfaPanel())
    )
  );

  /**
   * New Style Effects
   */

  /**
   * Load ~/.aws/credentials file
   */
  public loadConfigFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadAwsCredentialsFile),
      switchMap(() =>
        from(this.awsLogin.loadAwsCredentialsFile()).pipe(
          map((awsCredentialsFile) =>
            AwsLoginActions.loadAwsCredentialsFileSuccess({ awsCredentialsFile: awsCredentialsFile })
          ),
          catchError((error: unknown) =>
            of(AwsLoginActions.loadAwsCredentialsFileFailure({ error: (error as Error).message }))
          )
        )
      )
    )
  );
  /**
   * After file is loaded, load the Pulse Account Credentials
   */
  public loadConfigFileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadAwsCredentialsFileSuccess),
      map(() => AwsLoginActions.loadPulseAccountCredentials())
    )
  );

  /**
   * Load Pulse Account Credentials
   */
  public loadPulseAccountCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadPulseAccountCredentials),
      withLatestFrom(this._store$.pipe(select(selectCredentialsFile))),
      switchMap(([action, credentialsFile]) =>
        from(this.awsLogin.loadPulseAccountCredentials(credentialsFile)).pipe(
          map((result) =>
            AwsLoginActions.loadPulseAccountCredentialsSuccess({
              credentials: result.credentials,
              userName: result.userName,
            })
          ),
          catchError((error: unknown) =>
            of(AwsLoginActions.loadPulseAccountCredentialsFailure({ error: (error as Error).message }))
          )
        )
      )
    )
  );

  /**
   * After Pulse Account Credentials are loaded, load saved MFA credentials
   */
  public loadPulseAccountCredentialsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadPulseAccountCredentialsSuccess),
      map(() => AwsLoginActions.loadPulseMfaCredentials())
    )
  );

  /**
   * Load saved MFA credentials
   */
  public loadPulseMfaCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadPulseMfaCredentials),
      withLatestFrom(this._store$.pipe(select(selectCredentialsFile))),
      switchMap(([, credentialsFile]) =>
        from(this.awsLogin.loadPulseMfaCredentials(credentialsFile)).pipe(
          map((pulseMfaCredentialResult) =>
            AwsLoginActions.loadPulseMfaCredentialsSuccess({
              mfaCredentials: pulseMfaCredentialResult.credentials,
              mfaExpiration: pulseMfaCredentialResult.expirationDate,
            })
          ),
          catchError((error: unknown) =>
            of(AwsLoginActions.loadPulseMfaCredentialsFailure({ error: (error as Error).message }))
          )
        )
      )
    )
  );

  /**
   * Load Pulse Account Configs
   */
  public loadAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadAccounts),
      withLatestFrom(
        this._store$.pipe(select(AwsLoginSelectors.selectMfaCredentials)),
        this._store$.pipe(select(AwsLoginSelectors.selectHasPermission('CrossAccountAccess'))),
        this._store$.pipe(select(AwsLoginSelectors.selectIamGroups))
      ),
      switchMap(([, credentials, hasCrossAccountAccess, userIamGroups]) =>
        from(
          this.awsLogin.loadAwsAccounts(
            credentials,
            hasCrossAccountAccess,
            userIamGroups.map((group) => group.GroupName)
          )
        ).pipe(
          map((accounts) => AwsLoginActions.loadAccountsSuccess({ accounts: accounts })),
          catchError((error: unknown) => of(AwsLoginActions.loadAccountsFailure({ error: (error as Error).message })))
        )
      )
    )
  );

  /**
   * Login to all profiles
   */

  public loadAllAccountCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadAllAccountCredentials),
      withLatestFrom(this._store$.pipe(select(selectAccountConfigs))),
      concatMap(([, accountConfigs]) => {
        const actions = accountConfigs.map((accountConfig) =>
          AwsLoginActions.loadAccountCredentials({ accountConfig: accountConfig })
        );
        return from(actions);
      })
    )
  );

  /**
   * Load credentials for a single profile
   */
  public loadAccountCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadAccountCredentials),
      withLatestFrom(this._store$.pipe(select(selectCredentialsFile))),
      //Using mergemap to allow for paralell execution
      mergeMap(([action, credentialsFile]) =>
        from(this.awsLogin.loadAccountCredentialsFromCredentialFile(credentialsFile, action.accountConfig)).pipe(
          map((accountConfig) => AwsLoginActions.loadAccountCredentialsSuccess({ accountConfig: accountConfig })),
          catchError((error: unknown) =>
            of(
              AwsLoginActions.loadAccountCredentialsFailure({
                error: (error as Error).message,
                accountConfig: action.accountConfig,
              })
            )
          )
        )
      )
    )
  );

  /**
   * If no valid credentials are found, refresh the profile
   */
  public loadAccountCredentialsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadAccountCredentialsFailure),
      map((action) => {
        //For pulseapp account we need a different refresh method since it is assuming a different role
        if (action.accountConfig.AccountName === 'PulseApp') {
          return AwsLoginActions.refreshPulseAppCredentials();
        } else {
          return AwsLoginActions.refreshNonPulseAppAccountCredentials({ accountConfig: action.accountConfig });
        }
      })
    )
  );

  /**
   * Refresh profile for pulseapp account, this is done with the mfa credentials
   */
  public refreshPulseAppCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.refreshPulseAppCredentials),
      withLatestFrom(
        this._store$.pipe(select(AwsLoginSelectors.selectValuesForPulseAppAccountProfileRefresh)),
        this._store$.pipe(select(AwsLoginSelectors.selectHasPermission('AssumeRoleForPulseAppAccount')))
      ),
      concatMap(([, valuesForRefresh, assumesRoleForPulseAppAccount]) =>
        from(
          this.awsLogin.refreshCredentialsForPulseAppAccount(
            valuesForRefresh.mfaCredentials,
            valuesForRefresh.pulseAppAccountConfig,
            valuesForRefresh.userName,
            assumesRoleForPulseAppAccount,
            valuesForRefresh.mfaExpiration,
            valuesForRefresh.iamGroups.map((group) => group.GroupName)
          )
        ).pipe(
          map((result) => AwsLoginActions.refreshPulseAppCredentialsSuccess({ account: result.accountConfig })),
          catchError((error: unknown) =>
            of(AwsLoginActions.refreshPulseAppCredentialsFailure({ error: (error as Error).message }))
          )
        )
      )
    )
  );
  /**
   * After pulseapp account is refreshed, save credentials to ~/.aws/credentials
   */
  public refreshPulseAppCredentialsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.refreshPulseAppCredentialsSuccess),
      map(() => AwsLoginActions.saveAwsCredentialsFile())
    )
  );

  /**
   * After pulseapp account is refreshed, check if default profile should be overwritten
   */
  public refreshPulseAppCredentialsSuccess2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.refreshPulseAppCredentialsSuccess),
      withLatestFrom(
        this._store$.pipe(select(AwsLoginSelectors.selectHasPermission('OverrideDefaultProfileWithPulseAppProfile')))
      ),
      map(([action, hasPermission]) => {
        if (hasPermission) {
          return AwsLoginActions.overrideDefaultProfileWithPulseAppProfile();
        } else {
          return AwsLoginActions.noOpAction();
        }
      })
    )
  );

  /**
   * After overrideDefaultProfileWithPulseAppProfile action is fired, save credentials to ~/.aws/credentials
   */
  public overrideDefaultProfileWithPulseAppProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.overrideDefaultProfileWithPulseAppProfile),
      map(() => AwsLoginActions.saveAwsCredentialsFile())
    )
  );

  /**
   * Refresh profile for non-pulseapp account this is done with the pulseapp credentials
   */
  public refreshNonPulseAppAccountCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.refreshNonPulseAppAccountCredentials),
      withLatestFrom(
        this._store$.pipe(select(selectMfaCredentials)),
        this._store$.pipe(select(selectUserName)),
        this._store$.pipe(select(AwsLoginSelectors.selectIamGroups))
      ),
      concatMap(([action, mfaCredentials, userName, userIamGroups]) =>
        from(
          this.awsLogin.refreshCredentialsForNonPulseAppAccount(
            action.accountConfig,
            mfaCredentials,
            userName,
            userIamGroups.map((group) => group.GroupName)
          )
        ).pipe(
          map((result) =>
            AwsLoginActions.refreshNonPulseAppAccountCredentialsSuccess({ accountConfig: result.accountConfig })
          ),
          catchError((error: unknown) =>
            of(
              AwsLoginActions.refreshNonPulseAppAccountCredentialsFailure({
                error: (error as Error).message,
                accountConfig: action.accountConfig,
              })
            )
          )
        )
      )
    )
  );

  /**
   * After profile is refreshed, save the credentials to the ~/.aws/credentials file
   */
  public refreshNonPulseAppAccountCredentialsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.refreshNonPulseAppAccountCredentialsSuccess),
      map(() => AwsLoginActions.saveAwsCredentialsFile())
    )
  );

  /**
   * Save credentialFile from memory to ~/.aws/credentials
   */
  public saveCredentialsFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.saveAwsCredentialsFile),
      withLatestFrom(this._store$.pipe(select(selectCredentialsFile))),
      switchMap(([, credentialsFile]) =>
        from(this.awsLogin.writeAwsCredentialsFile(credentialsFile)).pipe(
          map(() => AwsLoginActions.saveAwsCredentialsFileSuccess()),
          catchError((error: unknown) =>
            of(AwsLoginActions.saveAwsCredentialsFileFailure({ error: (error as Error).message }))
          )
        )
      )
    )
  );

  /**
   * After MFA logout, route to mfa login page
   */
  public logoutMfa$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AwsLoginActions.logoutMfa),
        tap(() => {
          this._router.navigate(['/project/login']);
        })
      ),
    { dispatch: false }
  );

  /**
   * Load IAM Groups for User
   */
  public loadIamGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadIamGroups),
      withLatestFrom(this._store$.pipe(select(AwsLoginSelectors.selectMfaCredentials))),
      switchMap(([action, pulseAppCredentials]) =>
        from(this.awsLogin.loadIamGroupsForCurrentUser(pulseAppCredentials)).pipe(
          map((groups) => AwsLoginActions.loadIamGroupsSuccess({ iamGroups: groups })),
          catchError((error: unknown) => of(AwsLoginActions.loadIamGroupsFailure({ error: (error as Error).message })))
        )
      )
    )
  );

  public loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadPermissions),
      withLatestFrom(this._store$.pipe(select(AwsLoginSelectors.selectMfaCredentials))),
      switchMap(([action, pulseAppCredentials]) =>
        from(this.awsLogin.loadPermissionsFromDynamoDB(pulseAppCredentials)).pipe(
          map((permissions) => AwsLoginActions.loadPermissionsSuccess({ permissions })),
          catchError((error: unknown) =>
            of(AwsLoginActions.loadPermissionsFailure({ error: (error as Error).message }))
          )
        )
      )
    )
  );

  /**
   * Scheduling after MFA is loaded or user logged in with MFA
   */

  /**
   * After MFA credentials are loaded or user logged in with MFA, load Groups for the user
   */
  public loadGroupsAfterMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadPulseMfaCredentialsSuccess, AwsLoginActions.loginWithMfaSuccess),
      map(() => AwsLoginActions.loadIamGroups())
    )
  );

  /**
   * After loading Groups for the user, load permissions
   */
  public loadPermissionsAfterMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadIamGroupsSuccess),
      map(() => AwsLoginActions.loadPermissions())
    )
  );

  /**
   * After permissions are loded, load AWS Accounts
   */
  public loadAwsAccountsAfterPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadPermissionsSuccess),
      map(() => AwsLoginActions.loadAccounts())
    )
  );

  /**
   * After Accounts are loaded, load all profiles from ~/.aws/credentials
   */
  public loadProfilesAfterPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadAccountsSuccess),
      map(() => AwsLoginActions.loadAllAccountCredentials())
    )
  );

  /**
   * After Accounts are loaded, load the credentials for console login
   */
  public loadCredentialsForConsoleLoginAfterPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadAccountsSuccess),
      map(() => AwsLoginActions.loadConsoleRole())
    )
  );

  /**
   * AWS Console Login
   */

  /**
   * User triggers login. If the role for the console was already assumed, just sign in to the console. Else open the MFA panel for console login
   */
  public signInToAwsConsole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.signInToAwsConsole),
      withLatestFrom(
        this._store$.pipe(select(AwsLoginSelectors.selectValuesForAwsConsoleSignInUrlCreation)),
        this._store$.pipe(select(AwsLoginSelectors.selectQueuedAwsConsoleUrl))
      ),
      switchMap(([action, consoleSignInValues, queuedAwsConsoleUrl]) =>
        from(
          this.awsLogin.signInToAwsConsole(
            consoleSignInValues.awsConsoleCredentials,
            consoleSignInValues.awsConsoleCredentialsExpiry,
            queuedAwsConsoleUrl
          )
        ).pipe(
          map(() => AwsLoginActions.signInToAwsConsoleSuccess()),
          catchError((error: unknown) =>
            of(AwsLoginActions.signInToAwsConsoleFailure({ error: (error as Error).message }))
          )
        )
      )
    )
  );

  public openLinkInAwsConsole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.openLinkInAwsConsole),
      withLatestFrom(this._store$.pipe(select(AwsLoginSelectors.selectValuesForAwsConsoleSignInUrlCreation))),
      switchMap(([action, consoleSignInValues]) =>
        from(
          this.awsLogin.signInToAwsConsole(
            consoleSignInValues.awsConsoleCredentials,
            consoleSignInValues.awsConsoleCredentialsExpiry,
            action.url
          )
        ).pipe(
          map(() => AwsLoginActions.openLinkInAwsConsoleSuccess({ url: action.url })),
          catchError((error: unknown) =>
            of(AwsLoginActions.signInToAwsConsoleFailure({ error: (error as Error).message }))
          )
        )
      )
    )
  );

  /**
   * If the signin fails, open the MFA panel for console login
   */
  public signInToAwsConsoleFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.signInToAwsConsoleFailure),
      map((action) => AwsLoginActions.openAwsConsoleMfaPanel()),
      tap(() => {
        this.awsLogin.routeToLoginPage();
        this._toastyService.error('Login failed. Please try again.');
      })
    )
  );

  /**
   * User logs in with MFA for console login. Assume role for the console and sign in to the console
   */
  public assumeRoleForConsoleAccessWithMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.assumeRoleForConsoleAccessWithMfa),
      withLatestFrom(
        this._store$.pipe(select(AwsLoginSelectors.selectPulseAccountProfileCredentials)),
        this._store$.pipe(select(AwsLoginSelectors.selectPulseAppAccountConfig)),
        this._store$.pipe(select(AwsLoginSelectors.selectIamGroups)),
        this._store$.pipe(select(AwsLoginSelectors.selectUserName))
      ),
      switchMap(([action, pulseAccountProfileCredentials, pulseAppAccountConfig, userIamGroups, userName]) =>
        from(
          this.awsLogin.signInToAwsConsoleWithMfa(
            action.mfaCode,
            pulseAccountProfileCredentials,
            pulseAppAccountConfig,
            userName,
            userIamGroups.map((group) => group.GroupName),
            action.roleToAssume
          )
        ).pipe(
          map((result) =>
            AwsLoginActions.assumeRoleForConsoleAccessWithMfaSuccess({
              assumedRoleForConsoleCredentials: result.credentials,
              expiration: result.expiration,
            })
          ),
          catchError((error: unknown) =>
            of(AwsLoginActions.assumeRoleForConsoleAccessWithMfaFailure({ error: (error as Error).message }))
          )
        )
      )
    )
  );

  /**
   * Save credentials file after console role is assumed
   */
  public saveCredentialsFileAfterAssumingRoleForConsoleAccessWithMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.assumeRoleForConsoleAccessWithMfaSuccess),
      map(() => AwsLoginActions.saveAwsCredentialsFile())
    )
  );

  /**
   * After the role is assumed for console login, sign in to the console
   */
  public signInToAwsConsoleWithMfaSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.assumeRoleForConsoleAccessWithMfaSuccess),
      map(() => AwsLoginActions.signInToAwsConsole())
    )
  );

  /**
   * Load console role from config file
   */
  public loadConsoleRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AwsLoginActions.loadConsoleRole),
      withLatestFrom(this._store$.pipe(select(AwsLoginSelectors.selectCredentialsFile))),
      switchMap(([action, credentialsFile]) =>
        from(this.awsLogin.loadConsoleCredentialsFromFile(credentialsFile)).pipe(
          map((consoleRole) =>
            AwsLoginActions.loadConsoleRoleSuccess({
              credentials: consoleRole.credentials,
              expiration: consoleRole.expirationDate,
            })
          ),
          catchError((error: unknown) =>
            of(AwsLoginActions.loadConsoleRoleFailure({ error: (error as Error).message }))
          )
        )
      )
    )
  );
}
