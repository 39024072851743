<ul class="sidebar-ul" *ngIf="items" [id]="childrenContainerId">
  <ng-container *ngFor="let item of items; trackBy: trackByPath">
    <li
      *ngIf="service.showItem(item) && (item.data?.disabled$ | async) !== true"
      class="sidebar-li"
      #navigationElement
    >
      <!-- [ngClass]="{ active: service.isLinkActive(path + '/' + child.path) }" [class.selected]="service.isLinkActive(path + '/' + child.path) && !child.children" -->
      <pulse-sidebar-item
        *ngIf="
          item.navigationType !== itemNavigationType.Submenu;
          else subMenuNavigationItemTemplate
        "
        [parentIcon]="icon"
        [parentPath]="path"
        [item]="item"
        [navigationElement]="navigationElement"
        (itemClick)="emitClick(item, true)"
      ></pulse-sidebar-item>

      <ng-template #subMenuNavigationItemTemplate>
        <pulse-sidebar-item
          [item]="item"
          [navigationElement]="navigationElement"
        ></pulse-sidebar-item>
      </ng-template>
    </li>
  </ng-container>
</ul>
