import { Action, createReducer, on } from '@ngrx/store';
import { UserBase } from '@pulse/api/core';
import { UserClaim } from '../../interfaces';
import * as UserActions from '../actions/user.actions';

export const UserFeatureKey = 'user';

export interface UserState {
  userClaim: UserClaim | undefined;
  userProxy: UserBase | undefined;
}

export const initialUserState: UserState = {
  userClaim: undefined,
  userProxy: undefined,
};

const createUserReducer = createReducer(
  initialUserState,

  on(UserActions.updateUserClaim, (state, { user }) => ({
    ...state,
    userClaim: user,
  })),
  on(UserActions.resetUserProxy, (state) => ({
    ...state,
    userProxy: undefined,
  })),
  on(UserActions.loadUserProxySuccess, (state, { user }) => ({
    ...state,
    userProxy: user,
  }))
);

export function userReducer(state: UserState | undefined, action: Action): UserState {
  return createUserReducer(state, action);
}
