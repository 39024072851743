import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiCoreModule } from '@pulse/api/core';
import { CountryPipe, LanguageCodetIsoPipe } from './pipes';

@NgModule({
  imports: [CommonModule, ApiCoreModule],
  declarations: [LanguageCodetIsoPipe, CountryPipe],
  exports: [LanguageCodetIsoPipe, CountryPipe],
})
export class UtilLocalizationModule {}
