import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Language } from '@pulse/shared/models';
import { Observable, of } from 'rxjs';

@Injectable()
export class TranslocoHttpLoaderService implements TranslocoLoader {
  constructor(private readonly _http: HttpClient) {}

  // eslint-disable-next-line rxjs/finnish
  getTranslation(lang: string): Observable<Translation> {
    if (lang === Language.KEY) return of({});

    return this._http.get<Translation>(`./assets/i18n/${lang}.json`);
  }
}
