import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import {
  SidebarActionComponent,
  SidebarActionlistComponent,
  SidebarItemComponent,
  SidebarItemlistComponent,
  SidebarLinkComponent,
  SidebarNavComponent,
  SidebarSubmenuComponent,
} from './components';
import { SidebarService } from './services';

@NgModule({
  imports: [CommonModule, RouterModule, TranslocoModule, FormsModule, A11yModule],
  declarations: [
    SidebarActionComponent,
    SidebarActionlistComponent,
    SidebarItemComponent,
    SidebarItemlistComponent,
    SidebarLinkComponent,
    SidebarNavComponent,
    SidebarSubmenuComponent,
  ],
  exports: [SidebarNavComponent],
  providers: [SidebarService],
})
export class SidebarNavModule {}
