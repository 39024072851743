import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { ResourceDTO } from '@pulse/api/core';
import { resourceAdapter, ResourceFeatureKey, ResourceState } from '../reducers/ressource.reducer';
import { selectParentId, selectProjectId } from './router.selectors';

export const getResourceState = createFeatureSelector<ResourceState>(ResourceFeatureKey);

const { selectAll, selectEntities } = resourceAdapter.getSelectors(getResourceState);

export const resourcesLoading = createSelector(getResourceState, (state: ResourceState) => state.loading);
const resourceIdsDisabled = createSelector(getResourceState, (state: ResourceState) => state.disabledResourceIds);

export const getAllResources = createSelector(selectAll, (entities) => entities);

export const getProjectResources = createSelector(getAllResources, selectProjectId, (resources, projectId) =>
  resources.filter((r) => r.projectId === projectId)
);

export const getLandingPageResources = createSelector(getProjectResources, (projectResources) =>
  projectResources.filter((x) => x.flagProjectPage)
);
export const getSidebarResources = createSelector(
  getProjectResources,
  resourceIdsDisabled,
  (projectResources, disabledResourceIds) =>
    projectResources.filter((x) => x.flagProjectPage && !disabledResourceIds.includes(x.id))
);

export const getResourceEntities = createSelector(selectEntities, (entities) => entities);

// causes undefined loading errors on https://siemens.pulse.cloud/project/54/questionnaire/21924
// export const getSelectedResourceId = createSelector(getResourceState, (state: ResourceState) => state.selectedId);

export const getSelectedResourceId = createSelector(getResourceEntities, getResourceState, (entities, state) =>
  state.selectedId && entities[state.selectedId] ? state.selectedId : undefined
);

export const getSelectedResource = createSelector(getResourceEntities, getSelectedResourceId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

export const getCurrentChildResourcesByType = (
  resourceTypeIds: number[]
): MemoizedSelector<object, ResourceDTO[], DefaultProjectorFn<ResourceDTO[]>> =>
  createSelector(selectAll, getSelectedResourceId, (entities, selectedId) =>
    selectedId
      ? entities.filter((e) => resourceTypeIds.includes(e.resourceTypeId) && e.parentResourceIds?.includes(selectedId))
      : []
  );

export const getResourcesByType = (
  resourceTypeIds: number[]
): MemoizedSelector<object, ResourceDTO[], DefaultProjectorFn<ResourceDTO[]>> =>
  createSelector(selectAll, (entities) => entities.filter((e) => resourceTypeIds.includes(e.resourceTypeId)));

export const getSelectedParentResource = createSelector(getResourceEntities, selectParentId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

export const getSelectedParentResourceId = createSelector(getResourceEntities, selectParentId, (entities, selectedId) =>
  selectedId && entities[selectedId] ? selectedId : undefined
);

export const getHasUpdatePermission = createSelector(
  getSelectedResource,
  (resource) => resource?.actions?.includes('Update') ?? false
);

export const getHasAttachPermission = createSelector(
  getSelectedResource,
  (resource) => resource?.actions?.includes('Attach') ?? false
);

export const getHasDetachPermission = createSelector(
  getSelectedResource,
  (resource) => resource?.actions?.includes('Detach') ?? false
);

export const getHasUpdateOrAttachAndDetachPermission = createSelector(
  getHasUpdatePermission,
  getHasAttachPermission,
  getHasDetachPermission,
  (hasUpdatePermission, hasAttachPermission, hasDetachPermission) =>
    hasUpdatePermission || (hasAttachPermission && hasDetachPermission)
);
