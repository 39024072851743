import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ResourceDTO, ResourceFull } from '@pulse/api/core';
import * as InitActions from '../actions/init.actions';
import * as ResourceActions from '../actions/resource.actions';

export const ResourceFeatureKey = 'resource';

export interface ResourceState extends EntityState<ResourceDTO> {
  selectedId: number | undefined;
  disabledResourceIds: number[];
  loading: boolean;
}

export const resourceAdapter: EntityAdapter<ResourceDTO> = createEntityAdapter<ResourceDTO>();

export const initialRessourceState: ResourceState = resourceAdapter.getInitialState({
  loading: false,
  disabledResourceIds: [],
  selectedId: undefined,
});

const createResourceReducer = createReducer(
  initialRessourceState,

  on(
    InitActions.loadInit,
    ResourceActions.loadResources,
    ResourceActions.loadResource,
    ResourceActions.updateResource,
    ResourceActions.createResource,
    (state): ResourceState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    InitActions.loadInitSuccess,
    (state, { init }): ResourceState =>
      resourceAdapter.setAll(init.resources ?? [], {
        ...state,
        loading: false,
      })
  ),
  on(
    ResourceActions.loadResourceSuccess,
    (state, { resource }): ResourceState =>
      resourceAdapter.setOne(resource, {
        ...state,
        loading: false,
      })
  ),
  on(
    ResourceActions.loadResourcesSuccess,
    (state, { resources }): ResourceState =>
      resourceAdapter.upsertMany(resources, {
        ...state,
        loading: false,
      })
  ),
  /**
   * Todo: We need to make this work for multiple resource fulls too.
   * This is only a temporary solution.
   */
  on(ResourceActions.loadResourceFullSuccess, (state, { resource }): ResourceState => {
    let resourceState = resourceAdapter.removeMany(
      (r) => !!r.parentResourceIds && r.parentResourceIds.includes(resource.id),
      state
    );
    resourceState = resourceAdapter.upsertMany(flattenResource(resource.childResources ?? []), {
      ...resourceState,
      loading: false,
    });

    return resourceState;
  }),
  on(
    ResourceActions.selectResource,
    (state, { resourceId }): ResourceState => ({
      ...state,
      selectedId: resourceId,
    })
  ),
  on(
    ResourceActions.updateResourceSuccess,
    ResourceActions.createResourceSuccess,
    (state, { resource }): ResourceState =>
      resourceAdapter.setOne(resource, {
        ...state,
        selectedId: resource.id,
        loading: false,
      })
  ),
  on(
    ResourceActions.disableResources,
    (state, { resourceIds }): ResourceState => ({
      ...state,
      disabledResourceIds: [...new Set([...state.disabledResourceIds, ...resourceIds])],
    })
  ),
  on(
    ResourceActions.enableResources,
    (state, { resourceIds }): ResourceState => ({
      ...state,
      disabledResourceIds: state.disabledResourceIds.filter((x) => !resourceIds.includes(x)),
    })
  ),
  on(
    ResourceActions.createResourceFailure,
    ResourceActions.loadResourcesFailure,
    ResourceActions.updateResourceFailure,
    ResourceActions.loadResourceFailure,
    (state): ResourceState => ({
      ...state,
      loading: false,
    })
  )
);

export function resourceReducer(state: ResourceState | undefined, action: Action): ResourceState {
  return createResourceReducer(state, action);
}

const flattenResource = (children: ResourceFull[]): ResourceDTO[] =>
  children.reduce((flatList: ResourceDTO[], resource) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { childResources, resourceToResourceProperties, ...reducedResource } = resource;

    if ((resource.childResources?.length ?? 0) > 0) {
      flatList.push(reducedResource, ...flattenResource(resource.childResources ?? []));
    } else {
      flatList.push(reducedResource);
    }
    return flatList;
  }, []);
