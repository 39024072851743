import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ResourceFull } from '@pulse/api/core';
import * as ResourceActions from '../actions/resource.actions';

export const R2rFeatureKey = 'r2rProperty';

export interface R2rProperty {
  parentId: number;
  resourceId: number;
  properties: { [key: string]: string };
}

export type R2rPropertyState = EntityState<R2rProperty>;

export const r2rPropertyAdapter: EntityAdapter<R2rProperty> = createEntityAdapter<R2rProperty>({
  selectId: (r2r) => combineKeys(r2r.parentId, r2r.resourceId),
});

const initialState: R2rPropertyState = r2rPropertyAdapter.getInitialState();

export const r2rPropertyReducer = createReducer(
  initialState,

  on(ResourceActions.loadResourceFullSuccess, (state, { resource }) =>
    r2rPropertyAdapter.upsertMany(flattenResource(resource.id, resource.childResources ?? []), state)
  )
);

const flattenResource = (parentId: number, children: ResourceFull[]): R2rProperty[] =>
  children.reduce((flatList: R2rProperty[], resource) => {
    if (resource.resourceToResourceProperties) {
      flatList.push({
        parentId: parentId,
        resourceId: resource.id,
        properties: resource.resourceToResourceProperties,
      });
    }

    if ((resource.childResources?.length ?? 0) > 0) {
      flatList.push(...flattenResource(resource.id, resource.childResources ?? []));
    }

    return flatList;
  }, []);

export const combineKeys = (parentId: number, resourceId: number): string =>
  parentId.toString() + '-' + resourceId.toString();
