import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { UserBase } from '@pulse/api/core';
import { UserClaim } from '../../interfaces';

export const updateUserClaim = createAction('[User] Update User Claim', props<{ user: UserClaim }>());

export const loadUserCurrent = createAction('[User] Load User Current', props<{ externalId: string }>());
export const loadUserCurrentSuccess = createAction('[User] Load User Current Success', props<{ user: UserBase }>());
export const loadUserCurrentFailure = createAction(
  '[User] Load User Current Failure',
  props<{ error: HttpErrorResponse }>()
);

export const resetUserProxy = createAction('[User] Reset User Proxy');
export const loadUserProxy = createAction('[User] Load User Proxy', props<{ userId: number }>());
export const loadUserProxySuccess = createAction('[User] Load User Proxy Success', props<{ user: UserBase }>());
export const loadUserProxyFailure = createAction(
  '[User] Load User Proxy Failure',
  props<{ error: HttpErrorResponse }>()
);
