export const ICONS = {
  account_balance: 'icon-account_balance',
  account_box: 'icon-account_box',
  account_circle: 'icon-account_circle',
  action_management: 'icon-action-management',
  add_document: 'icon-add-document',
  add_person: 'icon-add-person',
  add_question: 'icon-add-question',
  add_row: 'icon-add-row',
  add_user: 'icon-add-user',
  add_box: 'icon-add_box',
  add_call: 'icon-add_call',
  add_circle: 'icon-add_circle',
  add_circle_outlinecontrol_point: 'icon-add_circle_outlinecontrol_point',
  add_comment: 'icon-add_comment',
  add_to_queue: 'icon-add_to_queue',
  addrow: 'icon-addrow',
  adduser: 'icon-adduser',
  analyze: 'icon-analyze',
  analyze_active: 'icon-analyze-active',
  apps: 'icon-apps',
  archive: 'icon-archive',
  arrow_back: 'icon-arrow_back',
  arrow_down: 'icon-arrow_down',
  arrow_down_double: 'icon-arrow_down_double',
  arrow_forward: 'icon-arrow_forward',
  arrow_left: 'icon-arrow_left',
  arrow_left_double: 'icon-arrow_left_double',
  arrow_right: 'icon-arrow_right',
  arrow_right_double: 'icon-arrow_right_double',
  arrow_top_double: 'icon-arrow_top_double',
  arrow_up: 'icon-arrow_up',
  arrowdown: 'icon-arrowdown',
  arrowup: 'icon-arrowup',
  attachment: 'icon-attachment',
  back: 'icon-back',
  backspace: 'icon-backspace',
  bar_chart: 'icon-bar_chart',
  bar_chart_1: 'icon-bar_chart_1',
  block: 'icon-block',
  bolt: 'icon-bolt',
  bookmark: 'icon-bookmark',
  bookmark_outlineturned_in_not: 'icon-bookmark_outlineturned_in_not',
  bubble_chart: 'icon-bubble_chart',
  building: 'icon-building',
  burger: 'icon-burger',
  business: 'icon-business',
  cake: 'icon-cake',
  calendar: 'icon-calendar',
  calendar_empty: 'icon-calendar_empty',
  call: 'icon-call',
  capslock: 'icon-capslock',
  car: 'icon-car',
  charging: 'icon-charging',
  chart_bar: 'icon-chart-bar',
  chat: 'icon-chat',
  chat_bubble: 'icon-chat_bubble',
  chat_bubble_outline: 'icon-chat_bubble_outline',
  check: 'icon-check',
  check_mark: 'icon-check-mark',
  check_mark2: 'icon-check-mark2',
  check_box: 'icon-check_box',
  check_box_indeterminate: 'icon-check_box_indeterminate',
  check_box_outline_blank: 'icon-check_box_outline_blank',
  choice_grid: 'icon-choice-grid',
  circle_outline: 'icon-circle_outline',
  clock: 'icon-clock',
  close: 'icon-close',
  close2: 'icon-close2',
  cloud: 'icon-cloud',
  cloud_upload: 'icon-cloud_upload',
  cloud_done: 'icon-cloud_done',
  cloud_download: 'icon-cloud_download',
  cloud_queue: 'icon-cloud_queue',
  code: 'icon-code',
  color_palette: 'icon-color_palette',
  column_add: 'icon-column-add',
  column_remove: 'icon-column-remove',
  comment: 'icon-comment',
  comment2: 'icon-comment2',
  company: 'icon-company',
  contacts: 'icon-contacts',
  content_copy: 'icon-content_copy',
  content_cut: 'icon-content_cut',
  createmode_editedit: 'icon-createmode_editedit',
  dashboard: 'icon-dashboard',
  dashboard2: 'icon-dashboard2',
  data_usage: 'icon-data_usage',
  delete: 'icon-delete',
  description: 'icon-description',
  detailview: 'icon-detailview',
  detailview1: 'icon-detailview1',
  disclaimer: 'icon-disclaimer',
  document: 'icon-document',
  document_any: 'icon-document-any',
  document_csv: 'icon-document-csv',
  document_png: 'icon-document-png',
  document_ppt: 'icon-document-ppt',
  document_txt: 'icon-document-txt',
  document_xls: 'icon-document-xls',
  documentppt: 'icon-documentppt',
  documentxls: 'icon-documentxls',
  donut_chart: 'icon-donut_chart',
  download: 'icon-download',
  download_csv: 'icon-download-csv',
  download_png: 'icon-download-png',
  download_ppt: 'icon-download-ppt',
  download_txt: 'icon-download-txt',
  download_xls: 'icon-download-xls',
  downloadcsv: 'icon-downloadcsv',
  downloadpdf: 'icon-downloadpdf',
  downloadpng: 'icon-downloadpng',
  downloadtxt: 'icon-downloadtxt',
  drafts: 'icon-drafts',
  drag: 'icon-drag',
  duplicate: 'icon-duplicate',
  edit: 'icon-edit',
  efficient: 'icon-efficient',
  error: 'icon-error',
  error_outline: 'icon-error_outline',
  euro: 'icon-euro',
  event_available: 'icon-event_available',
  event_busy: 'icon-event_busy',
  expand_left: 'icon-expand-left',
  expand_right: 'icon-expand-right',
  external_link: 'icon-external-link',
  eye: 'icon-eye',
  eye_blocked: 'icon-eye-blocked',
  favorite_outline: 'icon-favorite_outline',
  file: 'icon-file',
  file_copy: 'icon-file_copy',
  file_download: 'icon-file_download',
  file_upload: 'icon-file_upload',
  files: 'icon-files',
  filter: 'icon-filter',
  filter_active: 'icon-filter-active',
  filter_list: 'icon-filter_list',
  filter_list_alt: 'icon-filter_list_alt',
  find_in_page: 'icon-find_in_page',
  finding: 'icon-finding',
  fire: 'icon-fire',
  flagassistant_photo: 'icon-flagassistant_photo',
  folder: 'icon-folder',
  folder_favorite: 'icon-folder_favorite',
  folder_new: 'icon-folder_new',
  folder_open: 'icon-folder_open',
  folder_shared: 'icon-folder_shared',
  forum_answer: 'icon-forum_answer',
  free: 'icon-free',
  fullscreen: 'icon-fullscreen',
  fullscreen_exit: 'icon-fullscreen_exit',
  gift: 'icon-gift',
  gross_costs: 'icon-gross_costs',
  group: 'icon-group',
  group_add: 'icon-group_add',
  help_outline: 'icon-help_outline',
  home: 'icon-home',
  home2: 'icon-home2',
  home3: 'icon-home3',
  hybrid_grid: 'icon-hybrid-grid',
  idea: 'icon-idea',
  image: 'icon-image',
  import_export: 'icon-import_export',
  inbox: 'icon-inbox',
  info: 'icon-info',
  info2: 'icon-info2',
  info_outline: 'icon-info_outline',
  information: 'icon-information',
  input: 'icon-input',
  investment: 'icon-investment',
  invite: 'icon-invite',
  invite_user: 'icon-invite-user',
  inviteuser: 'icon-inviteuser',
  kanban: 'icon-kanban',
  key: 'icon-key',
  language: 'icon-language',
  layers: 'icon-layers',
  layers_clear: 'icon-layers_clear',
  library_addqueueadd_to_photos: 'icon-library_addqueueadd_to_photos',
  library_books: 'icon-library_books',
  lines: 'icon-lines',
  linkedin: 'icon-linkedin',
  location: 'icon-location',
  location_off: 'icon-location_off',
  lock: 'icon-lock',
  loopsync: 'icon-loopsync',
  low_priority: 'icon-low_priority',
  lp_bx_data: 'icon-lp_bx_data',
  lp_bx_layer: 'icon-lp_bx_layer',
  lp_bx_mobile_alt: 'icon-lp_bx_mobile_alt',
  lp_category: 'icon-lp_category',
  lp_chart_multitype: 'icon-lp_chart_multitype',
  lp_checkmark_outline: 'icon-lp_checkmark_outline',
  lp_cloud_download: 'icon-lp_cloud_download',
  lp_cloud_upload: 'icon-lp_cloud_upload',
  lp_dashboard: 'icon-lp_dashboard',
  lp_email: 'icon-lp_email',
  lp_face_neutral: 'icon-lp_face_neutral',
  lp_face_satisfied: 'icon-lp_face_satisfied',
  lp_forum: 'icon-lp_forum',
  lp_gruppe: 'icon-lp_gruppe',
  lp_information: 'icon-lp_information',
  lp_misuse_outline: 'icon-lp_misuse_outline',
  lp_search: 'icon-lp_search',
  lp_warning: 'icon-lp_warning',
  mail_thank: 'icon-mail-thank',
  mail_unread: 'icon-mail_unread',
  mailthanks: 'icon-mailthanks',
  medium: 'icon-medium',
  menu: 'icon-menu',
  menu1: 'icon-menu1',
  message: 'icon-message',
  mic: 'icon-mic',
  mic_none: 'icon-mic_none',
  mic_off: 'icon-mic_off',
  minus: 'icon-minus',
  mobile_erase: 'icon-mobile_erase',
  mobile_landscape: 'icon-mobile_landscape',
  mobile_lock: 'icon-mobile_lock',
  mobile_portrait: 'icon-mobile_portrait',
  mobile_setup: 'icon-mobile_setup',
  mode_comment: 'icon-mode_comment',
  money: 'icon-money',
  more_horizontal: 'icon-more_horizontal',
  more_vertical: 'icon-more_vertical',
  move_to_inbox: 'icon-move_to_inbox',
  network: 'icon-network',
  newsletter: 'icon-newsletter',
  not_interested: 'icon-not_interested',
  notifications: 'icon-notifications',
  notifications_none: 'icon-notifications_none',
  notifications_off: 'icon-notifications_off',
  organization: 'icon-organization',
  outlined_flag: 'icon-outlined_flag',
  pause: 'icon-pause',
  pause_circle_filled: 'icon-pause_circle_filled',
  pause_circle_outline: 'icon-pause_circle_outline',
  person: 'icon-person',
  person_outline: 'icon-person-outline',
  person_check: 'icon-person_check',
  pie_chart: 'icon-pie_chart',
  play_arrow: 'icon-play_arrow',
  play_circle_filled: 'icon-play_circle_filled',
  play_circle_outline: 'icon-play_circle_outline',
  playlist_add: 'icon-playlist_add',
  playlist_add_check: 'icon-playlist_add_check',
  plus: 'icon-plus',
  plus_circle: 'icon-plus-circle',
  power: 'icon-power',
  print: 'icon-print',
  priority_high: 'icon-priority_high',
  projectview: 'icon-projectview',
  protected_file: 'icon-protected-file',
  pulse: 'icon-pulse',
  quality: 'icon-quality',
  question: 'icon-question',
  quotation: 'icon-quotation',
  radio_button_checked: 'icon-radio_button_checked',
  rates: 'icon-rates',
  receive: 'icon-receive',
  recent_actors: 'icon-recent_actors',
  remind: 'icon-remind',
  remove_row: 'icon-remove-row',
  removerow: 'icon-removerow',
  repeat: 'icon-repeat',
  repeat_one: 'icon-repeat_one',
  replay: 'icon-replay',
  reporting: 'icon-reporting',
  reportingline_business: 'icon-reportingline-business',
  reportingline_governance: 'icon-reportingline-governance',
  reportingline_local: 'icon-reportingline-local',
  reportingline_shared: 'icon-reportingline-shared',
  request: 'icon-request',
  reset: 'icon-reset',
  resource_management: 'icon-resource-management',
  responserate: 'icon-responserate',
  save: 'icon-save',
  save_alt: 'icon-save_alt',
  savings: 'icon-savings',
  screen_rotation: 'icon-screen_rotation',
  search: 'icon-search',
  security: 'icon-security',
  select: 'icon-select',
  send: 'icon-send',
  sent: 'icon-sent',
  settings: 'icon-settings',
  settings_multiple: 'icon-settings_multiple',
  settings_single: 'icon-settings_single',
  share: 'icon-share',
  show: 'icon-show',
  show_in_columns: 'icon-show-in-columns',
  show_in_rows: 'icon-show-in-rows',
  show_chart: 'icon-show_chart',
  showincolumns: 'icon-showincolumns',
  showinrows: 'icon-showinrows',
  skip_next: 'icon-skip_next',
  skip_previous: 'icon-skip_previous',
  sort: 'icon-sort',
  sort2: 'icon-sort2',
  star: 'icon-star',
  star_outline: 'icon-star_outline',
  strategy: 'icon-strategy',
  swap_horiz: 'icon-swap_horiz',
  swap_vert: 'icon-swap_vert',
  tab: 'icon-tab',
  target: 'icon-target',
  text_format: 'icon-text_format',
  text_sms: 'icon-text_sms',
  thumb_down: 'icon-thumb_down',
  thumb_down_alt: 'icon-thumb_down_alt',
  thumb_up: 'icon-thumb_up',
  thumb_up_alt: 'icon-thumb_up_alt',
  thumbs_up_down: 'icon-thumbs_up_down',
  tiles: 'icon-tiles',
  today: 'icon-today',
  toggle_off: 'icon-toggle_off',
  toggle_on: 'icon-toggle_on',
  top_bottom: 'icon-top-bottom',
  translate: 'icon-translate',
  tree: 'icon-tree',
  trending_down: 'icon-trending_down',
  trending_up: 'icon-trending_up',
  twitter: 'icon-twitter',
  unarchive: 'icon-unarchive',
  unlock: 'icon-unlock',
  update: 'icon-update',
  upgrade: 'icon-upgrade',
  vertical_linechart: 'icon-vertical-linechart',
  video_collection: 'icon-video_collection',
  videocam: 'icon-videocam',
  videocam_off: 'icon-videocam_off',
  view_list: 'icon-view_list',
  view_module: 'icon-view_module',
  volume_down: 'icon-volume_down',
  volume_mute: 'icon-volume_mute',
  volume_off: 'icon-volume_off',
  volume_up: 'icon-volume_up',
  warning: 'icon-warning',
  word_cloud: 'icon-word-cloud',
  workspaces: 'icon-workspaces',
  world: 'icon-world',
  xing: 'icon-xing',
  zoom_in: 'icon-zoom_in',
  zoom_out: 'icon-zoom_out',
  zoom_out_map: 'icon-zoom_out_map',
};
