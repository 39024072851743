import { createSelector } from '@ngrx/store';
import { categoriesAdapter, InitState } from '../reducers/init.reducer';
import { getInitState } from './init.selectors';
import { selectProjectId } from './router.selectors';

const { selectAll, selectEntities } = categoriesAdapter.getSelectors();

export const getAllCategories = createSelector(getInitState, (state: InitState) => selectAll(state.categories));
export const getCategoryEntities = createSelector(getInitState, (state: InitState) => selectEntities(state.categories));
export const getProjectCategories = createSelector(getAllCategories, selectProjectId, (categories, projectId) =>
  categories.filter((x) => x.projectId === projectId)
);
