import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { API_BASE_URL as clientV1Url, ClientV1, ClientV1Cached } from './api-client-v1';
import { API_BASE_URL as clientV2Url, ClientV2, ClientV2Cached } from './api-client-v2';
import { API_BASE_URL as clientV3Url, ClientV3, ClientV3Cached } from './api-client-v3';

@NgModule({
  providers: [
    {
      provide: clientV1Url,
      useValue: '/core',
    },
    {
      provide: clientV2Url,
      useValue: '/core',
    },
    {
      provide: clientV3Url,
      useValue: '/core',
    },
    ClientV1,
    ClientV2,
    ClientV3,
    ClientV1Cached,
    ClientV2Cached,
    ClientV3Cached,
  ],
  imports: [CommonModule],
})
export class ApiCoreModule {}
