export enum Language {
  EN = 'en',
  DE = 'de',
  TR = 'tr',
  IT = 'it',
  FR = 'fr',
  ES = 'es',
  ZH = 'zh',
  KEY = 'key',
}
