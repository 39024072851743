import { Injectable, OnDestroy } from '@angular/core';
import { getBrowserLang, Translation, TranslocoService } from '@ngneat/transloco';
import { LanguageApiService } from '@pulse/api/core';
import { Language, LocalStorageEnum, ResourceTypeEnum } from '@pulse/shared/models';
import { SidebarActionItem, SidebarItemNavigationType, SidebarUserItem } from '@pulse/ui/sidebar-nav';
import { ToastyService } from '@pulse/ui/toasty';
import { PulseClientFacadeService } from '@pulse/util/pulse-client-state';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getIsoCodeTranslation } from '../../util/languages';

@Injectable({ providedIn: 'root' })
export class LanguageService implements OnDestroy {
  private readonly _subscriptions = new Subscription();
  private _languageSidebarActionItems?: SidebarActionItem[];

  private readonly _projectId$$ = new Subject<number>();
  private readonly _languageSidebarActionItems$$: ReplaySubject<SidebarActionItem[]> = new ReplaySubject<
    SidebarActionItem[]
  >(1);
  private readonly _currentLanguage$$ = new ReplaySubject<string>(1);
  private readonly _previousLanguage$$ = new BehaviorSubject<string>('');
  private readonly _projectTranslationsLoaded$$ = new BehaviorSubject<boolean>(false);
  private readonly _reloadProjectLanguage$$ = new Subject<void>();

  private readonly _projectTranslationsLoaded$ = this._projectTranslationsLoaded$$.pipe(
    filter((result) => !!result),
    map(() => void 0)
  );
  public languageSidebarActionItems$ = this._languageSidebarActionItems$$.asObservable();
  public currentLanguage$ = this._currentLanguage$$.asObservable();

  public projectTranslations = new Map<string, Translation>();

  constructor(
    private readonly _translateService: TranslocoService,
    private readonly _apiService: LanguageApiService,
    private readonly _toastyService: ToastyService,
    private readonly _pulseClientFacade: PulseClientFacadeService
  ) {
    this._subscribeToInitProjectTranslations();
    this._subscribeToLoadProjectTranslations();
    this._subscribeToReloadProjectTranslations();
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Loads the project languages and project translations
   * @param projectId id of the project
   * @returns
   */
  public initProjectTranslations$(projectId: number): Observable<void> {
    this._projectId$$.next(projectId);
    return this._projectTranslationsLoaded$;
  }

  /**
   * Language sidebar items
   * @returns
   */
  public getSidebarItem(): SidebarUserItem {
    return {
      navigationType: SidebarItemNavigationType.Submenu,
      title: 'Language',
      icon: 'icon-world',
      // eslint-disable-next-line rxjs/finnish
      subMenuItemSource: this.languageSidebarActionItems$,
      canSearchChildren: false,
    };
  }

  public reloadProjectTranslations(): void {
    this._reloadProjectLanguage$$.next();
  }

  public loadProjectTranslations$(projectId: number, language: string): Observable<void> {
    if (this.projectTranslations.has(language)) {
      return of(void 0);
    } else {
      return this._loadAndMergeProjectTranslations$(language, projectId, false).pipe(map(() => void 0));
    }
  }

  /**
   * Adds the project translations into a map and merges the translations into Transloco
   */
  public mergeProjectTranslations(
    language: string,
    projectTranslations: Translation,
    fallbackTranslations?: Translation
  ): void {
    if (fallbackTranslations) {
      // Merge project fallback language
      this._translateService.setTranslation(fallbackTranslations, Language.EN, { merge: true, emitChange: false });
    }
    // Store the translation in a map in order that other components can use the translations
    this.projectTranslations.set(language, projectTranslations);
    // Merge selected project language
    this._translateService.setTranslation(projectTranslations, language, { merge: true, emitChange: false });
  }

  /**
   * Sets the available languages
   * Adds EN and DE, if not included in project
   * @param availableLanguages available languages for either a project or a page
   * @returns the list of languages set for the TranslationService (Transloco)
   */
  public setAvailableLanguages(availableLanguages: string[] | Language[]): string[] {
    if (availableLanguages.filter((l) => l !== Language.KEY).length === 0) {
      availableLanguages.push(...[Language.EN, Language.DE]);
    }

    this._translateService.setAvailableLangs(availableLanguages);

    return availableLanguages;
  }

  /**
   * Gets the default language to be used,
   * depending on the browser Language and/or the language defined in the local storage
   * @param availableLanguages available languages for either a project or a page
   * @returns
   */
  public getBrowserDefaultLanguage(availableLanguages: string[]): string {
    // If the user has a language saved in browser, ensure that this language
    // will be added to the available language list for the translation lib
    const savedLanguage = localStorage.getItem(LocalStorageEnum.Language);

    // English will used as fallback-language
    let setLanguage: string = Language.EN;
    const browserLanguage = getBrowserLang();
    if (savedLanguage && availableLanguages.includes(savedLanguage)) {
      setLanguage = savedLanguage;
    } else if (browserLanguage && availableLanguages.includes(browserLanguage)) {
      setLanguage = browserLanguage;
    }
    // Set the language
    this._currentLanguage$$.next(setLanguage);

    return setLanguage;
  }

  /**
   * Sets the provided language to the TranslationService (Transloco)
   * and updates the local browser storage
   * @param language
   */
  public setActiveLanguage(language: string | Language): void {
    localStorage.setItem(LocalStorageEnum.Language, language);
    this._translateService.setActiveLang(language);
  }

  /**
   * @returns active language
   */
  public getActiveLanguage(): string {
    return this._translateService.getActiveLang();
  }

  private _subscribeToInitProjectTranslations(): void {
    this._subscriptions.add(
      this._projectId$$
        .pipe(
          distinctUntilChanged(),
          tap(() => {
            // Resetting Subjects is needed when changing between projects
            this._projectTranslationsLoaded$$.next(false);
            this._currentLanguage$$.next(undefined);
            this._previousLanguage$$.next('');
            this.projectTranslations.clear();
          }),
          switchMap((projectId) => this._apiService.loadLanguages$(projectId).pipe(catchError(() => of([])))),
          map((projectLanguages) => projectLanguages.map((pl) => pl.language ?? '')),
          switchMap((projectLanguages) => this._appendTranslationKeyLanguage$(projectLanguages)),
          map((projectLanguages) => this.setAvailableLanguages(projectLanguages)),
          map((languages) => ({ languages, setLanguage: this.getBrowserDefaultLanguage(languages) })),
          tap(({ languages, setLanguage }) => this._buildSidebarActionItems(languages, setLanguage))
        )
        .subscribe()
    );
  }

  private _subscribeToReloadProjectTranslations(): void {
    this._subscriptions.add(
      this._reloadProjectLanguage$$
        .pipe(
          withLatestFrom(this._currentLanguage$$, this._projectId$$),
          switchMap(([, currentLanguage, projectId]) =>
            this._loadAndMergeProjectTranslations$(currentLanguage, projectId, true)
          )
        )
        .subscribe()
    );
  }

  private _subscribeToLoadProjectTranslations(): void {
    this._subscriptions.add(
      this._currentLanguage$$
        .pipe(
          distinctUntilChanged(),
          filter((currentLanguage) => !!currentLanguage),
          tap((currentLanguage) =>
            this._translateService.setFallbackLangForMissingTranslation({
              fallbackLang: currentLanguage === Language.KEY ? Language.KEY : Language.EN,
            })
          ),
          switchMap((currentLanguage) => this._translateService.load(currentLanguage)),
          withLatestFrom(this._currentLanguage$$, this._projectId$$, this._previousLanguage$$),
          switchMap(([, currentLanguage, projectId, previousLanguage]) => {
            const loadFallbacks = previousLanguage === Language.EN || previousLanguage === '';
            return this._loadAndMergeProjectTranslations$(currentLanguage, projectId, loadFallbacks).pipe(
              map(() => currentLanguage)
            );
          }),
          tap((currentLanguage) => this._translateService.setActiveLang(currentLanguage)),
          tap(() => this._projectTranslationsLoaded$$.next(true))
        )
        .subscribe()
    );
  }

  private _loadAndMergeProjectTranslations$(
    currentLanguage: string,
    projectId: number,
    loadFallbackTranslations: boolean
  ): Observable<void> {
    if (currentLanguage === Language.KEY) {
      return of(void 0);
    }

    const apiCalls$ = Array<Observable<Translation>>();

    apiCalls$.push(this._apiService.loadProjectTranslations$(projectId, currentLanguage));

    if (loadFallbackTranslations) {
      apiCalls$.push(this._apiService.loadProjectFallbackTranslations$(projectId));
    }

    return forkJoin(apiCalls$).pipe(
      catchError((): Observable<Translation[]> => {
        this._toastyService.error('Failed to load the project specific translations');
        return of([{}, {}]);
      }),
      tap(([projectTranslations, fallbackTranslations]) =>
        this.mergeProjectTranslations(currentLanguage, projectTranslations, fallbackTranslations)
      ),
      map(() => void 0)
    );
  }

  /**
   * Builds the sidebar action items with the available languages
   * @param projectLanguages available languages
   */
  private _buildSidebarActionItems(projectLanguages: string[], currentLanguage: string): void {
    // Build the sidebar action items
    this._languageSidebarActionItems = projectLanguages.map((lang) => ({
      title: getIsoCodeTranslation(lang),
      isSelected$: new BehaviorSubject<boolean>(lang === currentLanguage),
      langCode: lang,
      icon: 'icon-translate',
      function: (actionItem: SidebarActionItem): void => {
        if (actionItem.langCode) {
          localStorage.setItem(LocalStorageEnum.Language, actionItem.langCode);
          this._previousLanguage$$.next(this._translateService.getActiveLang());
          this._currentLanguage$$.next(actionItem.langCode);
          this._languageSidebarActionItems?.forEach((langActionItem) => {
            langActionItem.isSelected$?.next(langActionItem.langCode === lang);
          });
        }
      },
    }));
    this._languageSidebarActionItems$$.next(this._languageSidebarActionItems);
  }

  /**
   * Appends the translation-key to the list of project languages, if the TranslationManagement ResourceType is available
   * @param projectLanguages list of project languages
   * @returns modified list of project languages
   */
  private _appendTranslationKeyLanguage$(projectLanguages: string[]): Observable<string[]> {
    return this._pulseClientFacade.resourceTypes$.pipe(
      map((projectResourceType) =>
        projectResourceType.some((item) => item.id === ResourceTypeEnum.TranslationManagement)
      ),
      map((hasTranslationManagement) =>
        hasTranslationManagement ? [...projectLanguages, Language.KEY] : projectLanguages
      )
    );
  }
}
