import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InfrastructureState } from '../../InfrastructureState';
import { AwsLoginSelectors } from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class AwsLoginFacadeService {
  public isMfaLoggedIn$ = this._store$.select(AwsLoginSelectors.selectIsMfaLoggedIn);
  public userName$ = this._store$.select(AwsLoginSelectors.selectUserName);

  constructor(private readonly _store$: Store<InfrastructureState>) {}
}
