import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { awsLoginReducer } from './+state/reducers/awslogin.reducer';
import { AwsLoginSchedulerService } from './services/aws-login-scheduler.service';
import { RouterModule } from '@angular/router';
import { ToastyModule } from '@pulse/ui/toasty';

@NgModule({
  imports: [CommonModule, RouterModule, ToastyModule],
  providers: [AwsLoginSchedulerService],
  exports: [],
})
export class UtilInfrastructureStateModule {}
