import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ToastyService } from '../../services/toasty/toasty.service';

@Component({
  selector: 'pulse-toasty',
  templateUrl: './toasty.component.html',
  styleUrls: ['./toasty.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ToastyComponent {
  constructor(public toastyService: ToastyService) {}
}
