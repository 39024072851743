import { Injectable } from '@angular/core';
import { TranslocoFallbackStrategy } from '@ngneat/transloco';
import { Language } from '@pulse/shared/models';

/**
 * Implements a custom strategy for loading fallback translation file,
 * when the selected active language has failed to load.
 */
@Injectable()
export class TranslationLoadFallbackService implements TranslocoFallbackStrategy {
  getNextLangs(failedLang: string): string[] {
    // Use English as last fallback language
    const fallbackLang: string[] = [Language.EN];
    if (failedLang.includes('-')) {
      // Failed to load a locale -> load the language instead
      const lang = failedLang.substring(0, failedLang.indexOf('-'));
      if (!fallbackLang.includes(lang)) fallbackLang.unshift(lang);
    }
    return fallbackLang;
  }
}
