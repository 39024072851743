/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable rxjs/no-exposed-subjects */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
//Use global strore
import { Injectable } from '@angular/core';
import * as ini from 'ini';

import { AccountConfig, loadAccounts } from '@pulse/util/infrastructure-state';

const fs: typeof import('fs') = (window as any).require('fs');
const path: typeof import('path') = (window as any).require('path');
const os: typeof import('os') = (window as any).require('os');
const AWS: typeof import('aws-sdk') = (window as any).require('aws-sdk');
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Router } from '@angular/router';
import { IAWSIamGroup } from 'libs/feature/infrastructure-developer/src/lib/models/interfaces/IAWSIamGroup';

//const con = require('electron').remote.getGlobal('console')
@Injectable({
  providedIn: 'root',
})
export class AwsLoginService {
  constructor(private _router: Router) {}

  /**
   * Credential Rotation Methods
   */

  /**
   * Rotates the pulseaccount credentials
   */
  public async rotatePulseAccountCredentials(props: {
    pulseAccountProfileCredentials?: AWS.Credentials | null;
    pulseMfaCredentials?: AWS.Credentials | null;
    userName?: string | null;
  }): Promise<AWS.Credentials> {
    //Check if any of the required props are null
    if (
      props.pulseAccountProfileCredentials === null ||
      props.pulseMfaCredentials === null ||
      props.userName === null
    ) {
      throw new Error('Rotating Credentials Failed! Required props are null');
    }
    //Check if any of the required props are undefined
    if (
      props.pulseAccountProfileCredentials === undefined ||
      props.pulseMfaCredentials === undefined ||
      props.userName === undefined
    ) {
      throw new Error('Rotating Credentials Failed! Required props are undefined');
    }

    //Create iam with mfa signed credentials
    const iamWithMfaCredentials = new AWS.IAM({ credentials: props.pulseMfaCredentials });

    //Generate new Access Key & Secret Access Key for pulseaccount
    const newPulseAccountAccessKeysResponse = await iamWithMfaCredentials
      .createAccessKey({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        UserName: props.userName,
      })
      .promise();
    console.log(
      'Created new access key for pulseaccount, access_key_id: ' +
        newPulseAccountAccessKeysResponse.AccessKey!.AccessKeyId
    );

    const newPulseAccountCredentials = new AWS.Credentials({
      accessKeyId: newPulseAccountAccessKeysResponse.AccessKey.AccessKeyId,
      secretAccessKey: newPulseAccountAccessKeysResponse.AccessKey.SecretAccessKey,
    });

    //Delete old access key from aws
    await iamWithMfaCredentials
      .deleteAccessKey({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        AccessKeyId: props.pulseAccountProfileCredentials.accessKeyId,
      })
      .promise();
    //Update currentPulseAccountCredentials for future use (future rotation)
    console.log(
      'Deleted old access key for pulseaccount, access_key_id: ' + props.pulseAccountProfileCredentials.accessKeyId
    );
    return newPulseAccountCredentials;
  }

  /**
   * New style stateless functions
   */

  /**
   *   This function is used to load the credentials from the ~/.aws/credentials file
   */
  public async loadAwsCredentialsFile(): Promise<{
    [key: string]: any;
  }> {
    const awsCredentialsFilePath = path.join(os.homedir(), '.aws', 'credentials');
    const configFile = ini.parse(fs.readFileSync(awsCredentialsFilePath, 'utf-8'));
    console.log('Loaded ~/.aws/credentials file');
    return configFile;
  }

  public async awsCredentialsFileExists(): Promise<boolean> {
    const awsCredentialsFilePath = path.join(os.homedir(), '.aws', 'credentials');
    return fs.existsSync(awsCredentialsFilePath);
  }

  public async createAwsCredentialsFile(): Promise<void> {
    const awsFolderPath = path.join(os.homedir(), '.aws');
    if (!fs.existsSync(awsFolderPath)) {
      fs.mkdirSync(awsFolderPath);
    }
    const awsCredentialsFilePath = path.join(os.homedir(), '.aws', 'credentials');
    fs.writeFileSync(awsCredentialsFilePath, '');
    console.log('Created ~/.aws/credentials file');
  }

  /**
   * This saves the credentials to the ~/.aws/credentials file
   */
  public async writeAwsCredentialsFile(
    awsCredentialsFile: {
      [key: string]: any;
    } | null
  ): Promise<void> {
    if (awsCredentialsFile === null) {
      throw new Error('awsCredentialsFile is null and will not be saved');
    }
    const awsCredentialsFileCopy = { ...awsCredentialsFile };
    const awsCredentialsFilePath = path.join(os.homedir(), '.aws', 'credentials');
    fs.writeFileSync(awsCredentialsFilePath, ini.encode(awsCredentialsFileCopy));
    console.log('Updated ~/.aws/credentials file');
  }

  /**
   * Loads pulse account credentials from credentials File object
   * @param credentialFile
   * @returns
   */
  public async loadPulseAccountCredentials(
    credentialFile: { [key: string]: any } | null
  ): Promise<{ credentials: AWS.Credentials; userName: string }> {
    if (credentialFile === null) {
      throw new Error('Credential file is null');
    }
    console.log('Loading Pulse Account Credentials from File');
    if (credentialFile['pulseaccount'] === undefined) {
      throw new Error('pulseaccount profile not found in credentials file');
    }
    if (
      credentialFile['pulseaccount']['aws_access_key_id'] === undefined ||
      credentialFile['pulseaccount']['aws_secret_access_key'] === undefined
    ) {
      throw new Error('pulseaccount profile is missing required credentials');
    }
    console.log('Found Pulse Account Credentials in Credentials File');
    const pulseAccountCredentials = new AWS.Credentials({
      accessKeyId: credentialFile['pulseaccount']['aws_access_key_id'],
      secretAccessKey: credentialFile['pulseaccount']['aws_secret_access_key'],
    });
    const username = await this.getUserNameFromAWS(pulseAccountCredentials);
    return {
      credentials: pulseAccountCredentials,
      userName: username,
    };
  }

  public async loadPulseMfaCredentials(
    credentialFile: {
      [key: string]: any;
    } | null
  ): Promise<{ credentials: AWS.Credentials; expirationDate: Date }> {
    if (credentialFile === null) {
      throw new Error('Credential file is null');
    }
    if (credentialFile['pulsemfa'] === undefined) {
      throw new Error('pulsemfa profile not found in credentials file');
    }
    if (
      credentialFile['pulsemfa']['aws_access_key_id'] === undefined ||
      credentialFile['pulsemfa']['aws_secret_access_key'] === undefined ||
      credentialFile['pulsemfa']['aws_session_token'] === undefined
    ) {
      throw new Error('pulsemfa profile is missing required credentials');
    }
    const pulseMfaCredentials = new AWS.Credentials({
      accessKeyId: credentialFile['pulsemfa']['aws_access_key_id'],
      secretAccessKey: credentialFile['pulsemfa']['aws_secret_access_key'],
      sessionToken: credentialFile['pulsemfa']['aws_session_token'],
    });
    const expirationDate = new Date(credentialFile['pulsemfa']['aws_expiration']);
    //Throw error if expiration date is in the past
    if (expirationDate.getTime() < Date.now()) {
      throw new Error('previously saved pulsemfa credentials have expired');
    }

    return { credentials: pulseMfaCredentials, expirationDate: new Date(credentialFile['pulsemfa']['aws_expiration']) };
  }

  public async loadAwsAccounts(
    credentialsForRequest?: AWS.Credentials | null,
    hasCrossAccountAccess?: boolean | null,
    userIamGroups?: string[] | null
  ): Promise<AccountConfig[]> {
    //Check if any input parameters are null or undefined
    if (credentialsForRequest === null || credentialsForRequest === undefined) {
      throw new Error('credentialsForRequest is null or undefined');
    }
    if (hasCrossAccountAccess === null || hasCrossAccountAccess === undefined) {
      throw new Error('hasCrossAccountAccess is null or undefined');
    }
    if (userIamGroups === null || userIamGroups === undefined) {
      throw new Error('userIamGroups is null or undefined');
    }

    console.log('User has cross account access: ' + hasCrossAccountAccess);

    //Load AWS Accounts from DynamoDB
    const dynamoDb = new AWS.DynamoDB.DocumentClient({ credentials: credentialsForRequest, region: 'eu-central-1' });
    const accountResponse = await dynamoDb.scan({ TableName: 'AccountConfig' }).promise();
    if (accountResponse.Items === undefined) {
      throw new Error('No accounts found in AccountConfig table');
    }
    const accounts = accountResponse.Items as AccountConfig[];

    for (const account of accounts) {
      account.AssumableRolesForUser = this._findAllRolesToAssume(account, userIamGroups);
      console.log(account.AssumableRolesForUser);
    }

    //If user does not have cross account access, filter out accounts that are not in the PulseApp account
    if (!hasCrossAccountAccess) {
      return accounts.filter((account) => account.AccountName === 'PulseApp');
    } else {
      //If user has cross account access, determine the roles they can assume in other accounts and generate the console role switch url
      for (const account of accounts) {
        if (account.AccountName !== 'PulseApp') {
          const consoleConfigUrl = await this.generateConsoleRoleSwitchUrl(account, userIamGroups);
          if (consoleConfigUrl !== null) {
            account.ConsoleConfigurationUrl = consoleConfigUrl;
          }
        }
      }
      return accounts;
    }
  }

  public async loadAccountCredentialsFromCredentialFile(
    credentialFile: {
      [key: string]: any;
    } | null,
    accountConfig: AccountConfig
  ): Promise<AccountConfig> {
    if (credentialFile === null) {
      throw new Error('Credential file is null');
    }
    if (credentialFile[accountConfig.ProfileName] === undefined) {
      throw new Error(`${accountConfig.ProfileName} profile not found in credentials file`);
    }
    if (
      credentialFile[accountConfig.ProfileName]['aws_access_key_id'] === undefined ||
      credentialFile[accountConfig.ProfileName]['aws_secret_access_key'] === undefined ||
      credentialFile[accountConfig.ProfileName]['aws_session_token'] === undefined ||
      credentialFile[accountConfig.ProfileName]['aws_expiration'] === undefined
    ) {
      throw new Error(`${accountConfig.ProfileName} profile is missing required credentials`);
    }
    const credentials = new AWS.Credentials({
      accessKeyId: credentialFile[accountConfig.ProfileName]['aws_access_key_id'],
      secretAccessKey: credentialFile[accountConfig.ProfileName]['aws_secret_access_key'],
      sessionToken: credentialFile[accountConfig.ProfileName]['aws_session_token'],
    });
    const expirationDate = new Date(credentialFile[accountConfig.ProfileName]['aws_expiration']);
    //Throw error if expiration date is in the past
    if (expirationDate.getTime() < Date.now()) {
      throw new Error(`previously saved ${accountConfig.ProfileName} credentials have expired`);
    }
    const accountConfigWithCredentials: AccountConfig = {
      ...accountConfig,
      Credentials: credentials,
      CredentialExpirationDate: expirationDate,
    };
    return accountConfigWithCredentials;
  }

  public async refreshCredentialsForPulseAppAccount(
    pulseMfaCredentials?: AWS.Credentials | null,
    accountConfig?: AccountConfig | null,
    userName?: string | null,
    assumesRoleForPulseAppAccount?: boolean | null, //Legacy parameter, not used
    mfaExpiration?: Date | null,
    userGroups?: string[] | null
  ): Promise<{ accountConfig: AccountConfig }> {
    if (userName === null || userName === undefined) {
      throw new Error('userName is undefined');
    }
    if (accountConfig === null || accountConfig === undefined) {
      throw new Error('accountConfig is undefined');
    }
    if (pulseMfaCredentials === null || pulseMfaCredentials === undefined) {
      throw new Error('pulseMfaCredentials is undefined');
    }
    if (assumesRoleForPulseAppAccount === null || assumesRoleForPulseAppAccount === undefined) {
      throw new Error('hasPermissionCrossAccountAdminAccess is undefined');
    }
    if (mfaExpiration === null || mfaExpiration === undefined) {
      throw new Error('mfaExpiration is undefined');
    }
    if (userGroups === null || userGroups === undefined) {
      throw new Error('userGroups is undefined');
    }

    //Otherwise pulsemfa credentials will be used for PulseApp account
    return {
      accountConfig: {
        ...accountConfig,
        Credentials: pulseMfaCredentials,
        CredentialExpirationDate: mfaExpiration,
      },
    };
  }

  public async refreshCredentialsForNonPulseAppAccount(
    accountConfig?: AccountConfig | null,
    pulseMfaCredentials?: AWS.Credentials | null,
    userName?: string | null,
    userGroups?: string[] | null
  ): Promise<{ accountConfig: AccountConfig }> {
    if (userName === null || userName === undefined) {
      throw new Error('userName is undefined');
    }
    if (accountConfig === null || accountConfig === undefined) {
      throw new Error('accountConfig is undefined');
    }
    if (pulseMfaCredentials === null || pulseMfaCredentials === undefined) {
      throw new Error('pulseMfaCredentials is undefined');
    }
    if (userGroups === null || userGroups === undefined) {
      throw new Error('userPermissions is undefined');
    }

    const roleToAssume = await this.findRoleToAssume(accountConfig, userGroups);

    if (roleToAssume === null) {
      throw new Error(
        `User ${userName} does not have permission to assume any roles in account ${accountConfig.AccountName}`
      );
    }

    console.log(`Refreshing credentials for ${accountConfig!.AccountName}`);
    //ARN of assumed role for PulseApp account
    const assumedRoleArn = `arn:aws:iam::${accountConfig.AccountNumber}:role/${roleToAssume}`;
    return this.refreshCredentialsForAccount(assumedRoleArn, accountConfig, pulseMfaCredentials, userName);
  }

  public async findRoleToAssume(accountConfig: AccountConfig, userGroups: string[]): Promise<string | null> {
    if (accountConfig.AssumableRoles === undefined || accountConfig.AssumableRoles === null) {
      return null;
    }
    const assumableRoles = this._findAllRolesToAssume(accountConfig, userGroups);

    if (assumableRoles && assumableRoles.length > 0) {
      if (assumableRoles.includes('PulseCDKAdminRole')) return 'PulseCDKAdminRole';
      if (assumableRoles.includes('PulseAdvancedDataAnalystGroup-assumed-role'))
        return 'PulseAdvancedDataAnalystGroup-assumed-role';
      return assumableRoles[0];
    }
    return null;
  }

  private _findAllRolesToAssume(accountConfig: AccountConfig | null, userGroups: string[] | null): string[] {
    if (accountConfig === null || accountConfig === undefined) {
      throw new Error('accountConfig is undefined');
    }
    if (userGroups === null || userGroups === undefined) {
      throw new Error('userPermissions is undefined');
    }

    if (accountConfig.AssumableRoles === undefined || accountConfig.AssumableRoles === null) {
      return [];
    }
    const assumableRoles = Object.keys(accountConfig.AssumableRoles);
    const rolesToAssume: Array<string> = [];
    assumableRoles.forEach((role) => {
      const groupsThatCanAssumeRole = accountConfig.AssumableRoles[role];
      //If user is in a group that can assume the role, then assume the role
      if (groupsThatCanAssumeRole.some((group) => userGroups.includes(group))) {
        rolesToAssume.push(role);
      }
    });
    return rolesToAssume;
  }

  public async refreshCredentialsForAccount(
    assumedRoleArn: string,
    accountConfig: AccountConfig,
    pulseMfaCredentials: AWS.Credentials,
    userName: string
  ): Promise<{ accountConfig: AccountConfig }> {
    const stsWithPulseAppCredentials = new AWS.STS({ credentials: pulseMfaCredentials });
    //Assume role for account
    try {
      const response = await stsWithPulseAppCredentials
        .assumeRole({ RoleArn: assumedRoleArn, RoleSessionName: `${userName}` })
        .promise();
      console.log(`Assumed role for ${accountConfig.AccountName}, access_key_id: ${response.Credentials!.AccessKeyId}`);

      if (!response.Credentials) {
        throw new Error('response.Credentials is undefined');
      }
      return {
        accountConfig: {
          ...accountConfig,
          Credentials: new AWS.Credentials({
            accessKeyId: response.Credentials.AccessKeyId,
            secretAccessKey: response.Credentials.SecretAccessKey,
            sessionToken: response.Credentials.SessionToken,
          }),
          CredentialExpirationDate: response.Credentials.Expiration,
        },
      };
    } catch (error) {
      console.log(error);
      throw new Error(`Error assuming role for ${accountConfig.AccountName}`);
    }
  }

  /**
   * Aws Login Methods
   */

  /**
   * Performs the login to aws using the mfa code
   * @param mfaCode mfa code
   */
  public async loginMfa(
    mfaCode: string,
    pulseAccountProfileCredentials: AWS.Credentials | null,
    userName: string | null
  ): Promise<{ credentials: AWS.Credentials; expiration: Date }> {
    if (pulseAccountProfileCredentials === null) {
      throw new Error('pulseAccountProfileCredentials is null');
    }
    console.log('Logging in to AWS with MFA code');
    //Create sts with current pulseaccount credentials
    const stsWithPulseAccountCredentials = new AWS.STS({
      credentials: pulseAccountProfileCredentials,
    });
    if (userName === null) {
      throw new Error('userName is null');
    }
    const mfaSerial = 'arn:aws:iam::917113436149:mfa/' + userName;
    console.log('MFA serial is: ' + mfaSerial);

    //Generate mfa signed credentials
    const mfaTempCredentialsResponse = await stsWithPulseAccountCredentials
      .getSessionToken({
        DurationSeconds: 43200,
        SerialNumber: mfaSerial,
        TokenCode: mfaCode,
      })
      .promise();
    console.log('Got MFA signed credentials, access_key_id: ' + mfaTempCredentialsResponse.Credentials!.AccessKeyId);
    return {
      credentials: new AWS.Credentials({
        accessKeyId: mfaTempCredentialsResponse.Credentials!.AccessKeyId,
        secretAccessKey: mfaTempCredentialsResponse.Credentials!.SecretAccessKey,
        sessionToken: mfaTempCredentialsResponse.Credentials!.SessionToken,
      }),
      expiration: new Date(mfaTempCredentialsResponse.Credentials!.Expiration),
    };
  }

  public async getUserNameFromAWS(pulseAccountProfileCredentials: AWS.Credentials): Promise<string> {
    const stsWithPulseAccountCredentials = new AWS.STS({
      credentials: pulseAccountProfileCredentials,
    });
    const callerIdentityResponse = await stsWithPulseAccountCredentials.getCallerIdentity().promise();
    const userArn = callerIdentityResponse.Arn;
    const userName = userArn!.split('/')[1];
    return userName;
  }

  public async signInToAwsConsole(
    awsConsoleCredentials?: AWS.Credentials | null,
    awsConsoleCredentialsExpiry?: Date | null,
    destination?: string | null
  ): Promise<void> {
    console.log('Signing in to AWS console' + awsConsoleCredentials + awsConsoleCredentialsExpiry);
    if (awsConsoleCredentials === undefined || awsConsoleCredentials === null) {
      throw new Error('awsConsoleCredentials is null');
    }
    if (awsConsoleCredentialsExpiry === undefined || awsConsoleCredentialsExpiry === null) {
      throw new Error('awsConsoleCredentialsExpiry is null');
    }

    //If awsConsoleCredentialsExpiry is in the past, throw an error
    if (awsConsoleCredentialsExpiry < new Date()) {
      throw new Error('awsConsoleCredentialsExpiry is in the past');
    }
    const loginUrl = await this.generateConsoleLoginUrl(awsConsoleCredentials, destination);
    console.log('Opening AWS console login url in external browser');
    (window as any).require('electron').shell.openExternal(loginUrl);
  }

  public async routeToLoginPage(): Promise<void> {
    console.log('Routing to login page');
    this._router.navigate(['/project/login']);
  }

  public async signInToAwsConsoleWithMfa(
    mfaCode?: string | null,
    pulseAccountProfileCredentials?: AWS.Credentials | null,
    pulseAppAccountConfig?: AccountConfig | null,
    userName?: string | null,
    userIamGroups?: string[] | null,
    roleToAssume?: string | null
  ): Promise<{ credentials: AWS.Credentials; expiration: Date }> {
    if (mfaCode === undefined || mfaCode === null) {
      throw new Error('mfaCode is null');
    }
    if (pulseAccountProfileCredentials === undefined || pulseAccountProfileCredentials === null) {
      throw new Error('pulseAccountProfileCredentials is null');
    }
    if (pulseAppAccountConfig === undefined || pulseAppAccountConfig === null) {
      throw new Error('pulseAppAccountConfig is null');
    }
    if (userName === undefined || userName === null) {
      throw new Error('userName is null');
    }
    if (userIamGroups === undefined || userIamGroups === null) {
      throw new Error('userIamGroups is null');
    }
    if (roleToAssume === undefined || roleToAssume === null) {
      throw new Error('roleToAssume is null');
    }

    const assumedRoleArn = `arn:aws:iam::${pulseAppAccountConfig.AccountNumber}:role/${roleToAssume}`;
    const assumedRoleCredentialsAndExpiration = await this.assumeRoleForConsoleAccess(
      pulseAccountProfileCredentials,
      mfaCode,
      userName,
      assumedRoleArn
    );

    return assumedRoleCredentialsAndExpiration;
  }

  /**
   * Uses the pulse account profile credentials to assume the role for the console access.
   * @param pulseAccountProfileCredentials
   * @param mfaCode
   * @param userName
   * @param roleArn
   * @returns
   */
  public async assumeRoleForConsoleAccess(
    pulseAccountProfileCredentials: AWS.Credentials,
    mfaCode: string,
    userName: string,
    roleArn: string
  ): Promise<{ credentials: AWS.Credentials; expiration: Date }> {
    const stsWithPulseAccountCredentials = new AWS.STS({
      credentials: pulseAccountProfileCredentials,
    });
    //Assume role
    const consoleAccessRoleCredentials = await stsWithPulseAccountCredentials
      .assumeRole({
        RoleArn: roleArn,
        RoleSessionName: userName,
        SerialNumber: 'arn:aws:iam::917113436149:mfa/' + userName,
        TokenCode: mfaCode,
        DurationSeconds: 43200 /*12 hours*/,
      })
      .promise();

    return {
      credentials: new AWS.Credentials({
        accessKeyId: consoleAccessRoleCredentials.Credentials!.AccessKeyId,
        secretAccessKey: consoleAccessRoleCredentials.Credentials!.SecretAccessKey,
        sessionToken: consoleAccessRoleCredentials.Credentials!.SessionToken,
      }),
      expiration: new Date(consoleAccessRoleCredentials.Credentials!.Expiration),
    };
  }

  /**
   * Load console credentials from credentials file
   */

  public async loadConsoleCredentialsFromFile(
    credentialFile: {
      [key: string]: any;
    } | null
  ): Promise<{ credentials: AWS.Credentials; expirationDate: Date }> {
    if (credentialFile === null) {
      throw new Error('Credential file is null');
    }
    if (credentialFile['pulseawsconsole'] === undefined) {
      throw new Error('pulseawsconsole profile not found in credentials file');
    }
    if (
      credentialFile['pulseawsconsole']['aws_access_key_id'] === undefined ||
      credentialFile['pulseawsconsole']['aws_secret_access_key'] === undefined ||
      credentialFile['pulseawsconsole']['aws_session_token'] === undefined ||
      credentialFile['pulseawsconsole']['aws_expiration'] === undefined
    ) {
      throw new Error('pulseawsconsole profile is missing required credentials');
    }
    const pulseMfaCredentials = new AWS.Credentials({
      accessKeyId: credentialFile['pulseawsconsole']['aws_access_key_id'],
      secretAccessKey: credentialFile['pulseawsconsole']['aws_secret_access_key'],
      sessionToken: credentialFile['pulseawsconsole']['aws_session_token'],
    });
    const expirationDate = new Date(credentialFile['pulseawsconsole']['aws_expiration']);
    //Throw error if expiration date is in the past
    if (expirationDate.getTime() < Date.now()) {
      throw new Error('previously saved pulseawsconsole credentials have expired');
    }
    return {
      credentials: pulseMfaCredentials,
      expirationDate: new Date(credentialFile['pulseawsconsole']['aws_expiration']),
    };
  }

  /**
   *
   * @param consoleCredentials
   * @returns
   */

  public async generateConsoleLoginUrl(
    consoleCredentials: AWS.Credentials,
    destination?: string | null
  ): Promise<string> {
    const signinToken = await this.generateConsoleSignInToken(consoleCredentials);
    let requestParameters = '?Action=login';
    requestParameters += '&Issuer=PulseInfrastructureClient';
    if (destination && destination !== null) {
      requestParameters += '&Destination=' + destination;
    } else {
      requestParameters += '&Destination=' + 'https://console.aws.amazon.com/';
    }

    requestParameters += '&SigninToken=' + signinToken;
    const consoleLoginUrl = 'https://signin.aws.amazon.com/federation' + requestParameters;
    return consoleLoginUrl;
  }

  private async generateConsoleSignInToken(pulseAppCredentials: AWS.Credentials): Promise<string> {
    const credentialJson = {
      sessionId: pulseAppCredentials.accessKeyId,
      sessionKey: pulseAppCredentials.secretAccessKey,
      sessionToken: pulseAppCredentials.sessionToken,
    };
    const credentialEncodedJson = encodeURIComponent(JSON.stringify(credentialJson));
    let requestParameters = '?Action=getSigninToken';

    //requestParameters += '&SessionDuration=1800';
    requestParameters += '&Session=' + credentialEncodedJson;

    const requestUrl = 'https://signin.aws.amazon.com/federation' + requestParameters;
    const response = await fetch(requestUrl);
    const responseJson = await response.json();
    const token = responseJson.SigninToken;
    return token;
  }

  private async generateConsoleRoleSwitchUrl(
    accountConfig: AccountConfig,
    userGroups: string[]
  ): Promise<string | null> {
    let destination = 'https://console.aws.amazon.com/';
    if (accountConfig.ProfileName === 'pulseapp') {
      //If pulseapp, just return the destination
    } else {
      //const roleName = 'PulseCDKDeveloperCrossAccountRole';
      const roleName = await this.findRoleToAssume(accountConfig, userGroups);
      if (roleName === null) {
        return null;
      }
      const accountNumber = accountConfig.AccountNumber;
      destination = `https://signin.aws.amazon.com/switchrole?roleName=${roleName}&account=${accountNumber}`;
      //To test later:
      destination += `&displayName=${accountConfig.AccountName}`;
    }
    return destination;
  }

  /**
   * Load IAM Groups for the current user
   */
  public async loadIamGroupsForCurrentUser(
    pulseAccountCredentials: AWS.Credentials | undefined | null
  ): Promise<IAWSIamGroup[]> {
    if (pulseAccountCredentials === undefined || pulseAccountCredentials === null) {
      throw new Error('pulseAccountProfileCredentials is undefined');
    }

    const userName = await this.getUserNameFromAWS(pulseAccountCredentials);
    const iam = new AWS.IAM({
      credentials: pulseAccountCredentials,
    });
    const response = await iam.listGroupsForUser({ UserName: userName }).promise();
    if (response.Groups === undefined) {
      throw new Error('Groups is undefined');
    }
    return response.Groups;
  }

  /**
   * Load Permissions from DynamoDB using document client
   */
  public async loadPermissionsFromDynamoDB(
    credentialsForRequest: AWS.Credentials | undefined | null
  ): Promise<{ [key: string]: string[] }> {
    if (credentialsForRequest === undefined || credentialsForRequest === null) {
      throw new Error('credentialsForRequest is undefined');
    }
    const documentClient = new AWS.DynamoDB.DocumentClient({
      credentials: credentialsForRequest,
      region: 'eu-central-1',
    });
    const response = await documentClient
      .scan({
        TableName: 'InfraClientAccessControl',
      })
      .promise();
    if (response.Items === undefined) {
      throw new Error('Items is undefined');
    }

    const permissions: { [key: string]: string[] } = {};
    response.Items.forEach((item) => {
      permissions[item['FeatureKey']] = item['Groups'];
    });
    return permissions;
  }
}
