import { Inject, Injectable, Optional } from '@angular/core';
import { HttpCacheClient } from '@pulse/api/http-cache';
import { API_BASE_URL, ClientV3 } from './api-client-v3';

@Injectable()
export class ClientV3Cached extends ClientV3 {
  constructor(httpClient: HttpCacheClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(httpClient, baseUrl);
  }
}
