<div
  *ngIf="message.type === toastyMessageType.Success; else msg"
  class="checkmark__container"
>
  <pulse-checkmark
    class="checkmark__content shadow"
    (click)="dismiss(message.id)"
  ></pulse-checkmark>
</div>
<ng-template #msg>
  <div
    class="message shadow {{ message.type }}"
    [class.slide]="dismissed$ | async"
  >
    <div class="header">
      <div class="title">
        <i [ngClass]="icon"></i>
        {{ message.title | transloco }}
      </div>
      <button class="delete" (click)="dismiss(message.id)">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="content">
      {{ message.content | transloco: message.contentParams }}
    </div>
  </div>
</ng-template>
