import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Project } from '@pulse/api/core';
import * as InitActions from '../actions/init.actions';
import * as ProjectActions from '../actions/project.actions';

export const ProjectFeatureKey = 'project';

export interface ProjectState extends EntityState<Project> {
  loading: boolean;
  error: HttpErrorResponse | undefined;
}

// export interface ProjectPartialState {
//   readonly [projectFeatureKey]: ProjectState;
// }

export const projectAdapter: EntityAdapter<Project> = createEntityAdapter<Project>();

export const initialProjectState: ProjectState = projectAdapter.getInitialState({
  loading: false,
  error: undefined,
});

const createProjectReducer = createReducer(
  initialProjectState,

  on(ProjectActions.loadProjects, (state) => ({
    ...state,
    loading: true,
  })),

  on(InitActions.loadInitSuccess, (state, { init }) =>
    projectAdapter.setOne(init.project, {
      ...state,
      loading: false,
    })
  ),
  on(InitActions.loadInitFailure, (state, { error }) => ({
    ...state,
    selectedId: undefined,
    error,
  })),
  on(ProjectActions.loadProjectsSuccess, (state, { projects }) =>
    projectAdapter.setAll(projects, {
      ...state,
      loading: false,
    })
  ),
  on(ProjectActions.loadProjectsFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function projectReducer(state: ProjectState | undefined, action: Action): ProjectState {
  return createProjectReducer(state, action);
}
