<div class="action" [class.highlight]="action.isSelected$ | async">
  <a
    [href]="action.path"
    [routerLink]="action.path"
    [queryParams]="action.pathQueryParams"
    (click)="action.function(action)"
    tabindex="0"
    class="action-link"
  >
    <i
      *ngIf="action.icon"
      class="action-link-icon"
      [class]="action.icon"
      aria-hidden="true"
    ></i>
    <div class="action-link-description-wrapper">
      <span class="action-link-title">
        {{ action.title | transloco }}
      </span>
      <span class="action-link-subtitle">
        {{ action.subtitle }}
      </span>
    </div>
  </a>
  <button
    [attr.aria-label]="'show action commands of ' + action?.title"
    class="action-command icon-more_horizontal"
    *ngIf="action.commands?.length > 0"
    (click)="setShowMore(true)"
    (mouseenter)="setShowMore(true)"
  ></button>
  <div
    class="action-commands"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
    *ngIf="showMore$ | async"
    (mouseleave)="setShowMore(false)"
    (keydown.escape)="handleKeyboardEvent($event)"
  >
    <button
      *ngFor="let command of action.commands"
      [attr.aria-label]="command.label | transloco"
      [class]="'action-command ' + command.icon"
      [title]="command.label | transloco"
      (click)="command.function(action)"
    ></button>
  </div>
</div>
